export default {
    namespaced: true,
    state: {
        items: []
    },
    mutations: {
        SET_WORKFLOW_TASKS(state, payload) {
            state.items = payload.tasks;
        }
    },
    actions: {
        fetchWorkflowTasksByRecordId(context, recordId) {
            let payload = {};
            payload.url = '/api/tasks/tasksByRecord/' + parseInt(recordId);
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_WORKFLOW_TASKS', {
                        recordId,
                        tasks: response.data.tasks,
                    });
                }
                return response;
            });
        }
    },
    getters: {
        getWorkflowTasks: state => state.items,
        hasWorkflowTasks: (state, getters) =>
            !!getters.getWorkflowTasks.length === true,
        hasActiveWorkflowTasks: (state, getters) =>
            !!getters.getWorkflowTasks.filter(x => x.status === 'open' || x.status === 'reopened').length === true,
    },
};
