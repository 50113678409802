import axios from 'axios';
import { eventBus } from '@/event-bus.js';
import {
    getToken,
    hasExpired,
    getRefreshToken,
    storeToken,
    deleteToken
} from '@/utils/auth';
import { store } from '../store/store';
import router from '../router';

const STAY_LOGGED_IN = 'STAY_LOGGED_IN';

const axiosClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
});
axiosClient.defaults.headers.common.Accept = `application/json, application/prs.lea+json;v=${process.env.VUE_APP_VERSION}`;

if (process.env.VUE_APP_DISABLE_INTERCEPTORS !== 'true') {
    axiosClient.interceptors.response.use(
        response => {
            if (
                typeof process.env.VUE_APP_REMOTE_API !== 'undefined' &&
                process.env.VUE_APP_REMOTE_API &&
                process.env.VUE_APP_VERSION !== response.headers['api-version']
            ) {
                eventBus.$emit('displayApiVersionConflictDialog');
            }
            return response;
        },
        error => {
            const originalRequest = error.config;
            const statusCode = error.response
                ? error.response.status
                : error.status;
            if (process.env.VUE_APP_SSO !== 'true' && statusCode === 401 && originalRequest.url === '/api/token/refresh') {
                store
                    .dispatch('authStore/logoutUser')
                    .then(() => router.push({ name: 'Login' }))
                return Promise.reject(error);
            }
            if (statusCode === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                if (process.env.VUE_APP_SSO === 'true') {
                    deleteToken();
                    store
                        .dispatch('authStore/logoutUser')
                        .then(() => router.push({ name: 'Login' }));
                    return Promise.reject(error);
                }

                if (
                    hasExpired(getToken()) &&
                    `${localStorage.getItem(STAY_LOGGED_IN)}` !== 'true'
                ) {
                    store
                        .dispatch('authStore/logoutUser')
                        .then(() => router.push({ name: 'Login' }));
                    return Promise.reject(error);
                }
                if (
                    `${localStorage.getItem(STAY_LOGGED_IN)}` === 'true' &&
                    getRefreshToken() && process.env.VUE_APP_SSO !== 'true'
                ) {
                    return axiosClient
                        .post('/api/token/refresh', {
                            refresh_token: getRefreshToken(),
                        })
                        .then(res => {
                            if (res.status === 200 || res.status === 201) {
                                storeToken(
                                    res.data.token,
                                    localStorage.getItem(STAY_LOGGED_IN),
                                    res.data.refresh_token
                                );

                                axiosClient.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
                                originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
                                return axios(originalRequest);
                            }
                        });
                }
            }

            if (statusCode !== 200 && statusCode !== 204 && originalRequest.url !== '/api/refreshMercureCookie') {
                // todo: implement logging (see branch feature/logger)
                const supportId =
                    error.response &&
                    error.response.data &&
                    error.response.data.supportId
                        ? error.response.data.supportId
                        : null;
                eventBus.$emit(
                    'setBaseResponseDialog',
                    statusCode,
                    true,
                    supportId
                );
            }
            return Promise.reject(error);
        }
    );
}

export default axiosClient;
