import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        staff: [],
    },
    mutations: {
        SET_STAFF(state, staffItems) {
            state.staff = staffItems;
        },
        ADD_TO_STAFF: (state, staffItem) => {
            state.staff.unshift(staffItem);
        },
        EDIT_STAFF: (state, staffItem) => {
            const editIndex = state.staff.findIndex(x => x.id === staffItem.id);
            Vue.set(state.staff, editIndex, staffItem);
        },
        REMOVE_STAFF: (state, staffItem) => {
            const removeIndex = state.staff.findIndex(
                x => x.id === staffItem.id
            );
            state.staff.splice(removeIndex, 1);
        },
    },
    actions: {
        search: (context, payload) => {
            payload.url = '/api/staff/search';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
        fetchAll(context, payload = {}) {
            payload.url = '/api/admin/staff/list';
            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_STAFF', response.data);
                }
                return response;
            });
        },
        fetchById: (context, payload = {}) => {
            payload.url = '/api/admin/staff/' + payload.id;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                return response;
            });
        },
        create(context, payload = {}) {
            payload.url = '/api/admin/staff/create';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('ADD_TO_STAFF', response.data);
                }
                return response;
            });
        },
        edit(context, payload = {}) {
            payload.url = '/api/admin/staff/edit';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('EDIT_STAFF', response.data);
                }
                return response;
            });
        },
        delete(context, payload = {}) {
            payload.url = '/api/admin/staff/delete';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('REMOVE_STAFF', response.data);
                }
                return response;
            });
        },
    },
    getters: {
        getStaff: state => state.staff.sort((a, b) => a.firstName.toLowerCase() - b.firstName.toLowerCase())
    },
};
