import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        items: [],
        detailedItems: [],
        itemsFetched: false,
        securityControlItems: [],
    },
    getters: {
        getItems: state => state.items,
        getDetailedItems: state => state.detailedItems,
        getDetailedSaasToolsWithVendor: state => state.detailedItems.filter(x => x.vendorId && x.vendorId > 0 && !x.onPremise),
        getIdItems: state => state.items.filter(x => x && x.id),
        getUuidItems: state => state.items.filter(x => x && x.clientId && !x.id),
        getSecurityControlItems: state => state.securityControlItems,
    },
    mutations: {
        SET_ITEMS(state, items) {
            state.items = items;
        },
        SET_DETAILED_ITEMS(state, items) {
            state.detailedItems = items;
        },
        SET_FETCHED(state, reset = false) {
            state.itemsFetched = !reset;
        },
        ADD_TOOL: (state, payload) => {
            const newItem = payload.data;
            const itemIndex = newItem.id ? state.items.findIndex(x => x && x.id === newItem.id) : state.items.findIndex(x => x && x.clientId === newItem.clientId);
            if(itemIndex === -1) {
                state.items.unshift(newItem);
            }
        },
        EDIT_TOOL(state, payload) {
            const itemIndex = state.items.findIndex(x => x && x.id === payload.id);
            Vue.set(state.items, itemIndex, {
                ...state.items[itemIndex],
                ...payload,
            });
        },
        REMOVE_TOOL(state, payload) {
            const itemIndex = state.items.findIndex(x => x && x.id === payload.id);
            Vue.delete(state.items, itemIndex);
        },
        SET_SECURITY_CONTROL_ITEMS(state, items) {
            state.securityControlItems = items;
        },
        ADD_SECURITY_CONTROL: (state, payload) => {
            const newItem = payload.data;
            state.securityControlItems.unshift(newItem);
        },
        EDIT_SECURITY_CONTROL(state, payload) {
            const itemIndex = state.securityControlItems.findIndex(x => x.id === payload.id);
            Vue.set(state.securityControlItems, itemIndex, {
                ...state.securityControlItems[itemIndex],
                ...payload,
            });
        },
        REMOVE_SECURITY_CONTROL(state, payload) {
            const itemIndex = state.securityControlItems.findIndex(x => x.id === payload.id);
            Vue.delete(state.securityControlItems, itemIndex);
        },
        
        
    },
    actions: {
        setItems: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_ITEMS', payload);
                resolve();
            }),
        unshiftItems: (context, payload) =>
            new Promise(resolve => {
                context.commit('ADD_TOOL', payload.data ? payload : {data: payload});
                resolve();
            }),
        addTool: (context, payload) =>
            new Promise(resolve => {
                context.commit('ADD_TOOL', payload.data ? payload : {data: payload});
                resolve();
            }),
        removeTool: (context, payload) =>
            new Promise(resolve => {
                context.commit('REMOVE_TOOL', payload);
                resolve();
            }),
        changeTool: (context, payload) =>
            new Promise(resolve => {
                context.commit('EDIT_TOOL', payload);
                resolve();
            }),
        fetch(context, payload = {}) {
            payload.url = '/api/tools';
            const disableStoreUpdate = payload.disableStoreUpdate || false;
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage && context.state.itemsFetched) {
                return {data: context.getters.getItems};
            }
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!disableStoreUpdate && !response.data.error) {
                    context.commit('SET_FETCHED');
                    context.commit('SET_ITEMS', response.data);
                }
                return response;
            });
        },
        fetchDetailed(context, payload = {}) {
            payload.url = '/api/tools/byToolIds';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_DETAILED_ITEMS', response.data);
                }
                return response;
            });
        },
        fetchById: (context, payload) => {
            payload.url = '/api/admin/toolById/' + payload.id;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                return response;
            });
        },
        create: (context, payload = {}) => {
            payload.url = '/api/admin/tools/add';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('ADD_TOOL', {
                        data: response.data,
                    });
                }
                return response;
            });
        },
        edit: (context, payload = {}) => {
            payload.url = '/api/admin/tools/edit';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('EDIT_TOOL', response.data);
                }
                return response;
            });
        },
        delete: (context, payload = {}) => {
            payload.url = '/api/admin/tools/delete';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('REMOVE_TOOL', payload);
                }
                return response;
            });
        },
        findUsage: (context, payload = {}) => {
            payload.url = '/api/admin/tools/findUsage';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
        replaceItemInRecord: (context, payload = {}) => {
            payload.url = '/api/admin/tools/replace';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
        clearDetailedItems({ commit }) {
            commit('SET_DETAILED_ITEMS', []);
        },
        clearItems({ commit }) {
            commit('SET_FETCHED', true);
            commit('SET_ITEMS', []);
            commit('SET_DETAILED_ITEMS', []);
        },
        fetchSecurityControlItems(context, payload = {}) {
            payload.url = '/api/securityControl/securityControl';
            const disableStoreUpdate = payload.disableStoreUpdate || false;
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage && context.getters.getSecurityControlItems && context.getters.getSecurityControlItems.length) {
                return {data: context.getters.getSecurityControlItems};
            }
            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!disableStoreUpdate && !response.data.error) {
                    context.commit('SET_SECURITY_CONTROL_ITEMS', response.data);
                }
                return response;
            });
        },
        fetchSecurityControlById: (context, payload) => {
            payload.url = '/api/admin/securityControlById/' + payload.id;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                return response;
            });
        },
        createSecurityControlItem: (context, payload = {}) => {
            payload.url = '/api/admin/securityControl/add';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('ADD_SECURITY_CONTROL', {
                        data: response.data,
                    });
                }
                return response;
            });
        },
        editSecurityControl: (context, payload = {}) => {
            payload.url = '/api/admin/securityControl/edit';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('EDIT_SECURITY_CONTROL', response.data);
                }
                return response;
            });
        },
        deleteSecurityControl: (context, payload = {}) => {
            payload.url = '/api/admin/securityControl/delete';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('REMOVE_SECURITY_CONTROL', payload);
                }
                return response;
            });
        },
        findUsageSecurityControl: (context, payload = {}) => {
            payload.url = '/api/admin/securityControl/findUsage';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
        replaceSecurityControlInTool: (context, payload = {}) => {
            payload.url = '/api/admin/securityControl/replace';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
    },
};
