<template>
    <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                v-model="model"
                :color="model ? 'primary' : 'grey lighten-1'"
                @click="model = !model"
                block
                class="v-btn-truncate"
                :disabled="disabled"
            >
                <v-icon left color="white" v-if="model">check_box</v-icon>
                <v-icon left color="white" v-else>check_box_outline_blank</v-icon>
                <div
                    class="text-left text-no-wrap text-truncate white--text"
                    style="width:100%"
                >
                    {{ title }}
                </div>
            </v-btn>
        </template>
        {{ title }}
    </v-tooltip>
</template>

<script>
export default {
    name: 'BaseCheckboxButton',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>
