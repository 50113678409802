import http from '@/utils/axios';

export default {
    namespaced: true,
    state: {
        hubUrl: null,
        leaUrl: null,
        apps: []
    },
    mutations: {
        SET_HUB_URL(state, hubUrl) {
            state.hubUrl = hubUrl;
        },
        SET_LEA_URL(state, leaUrl) {
            state.leaUrl = leaUrl;
        },
        SET_APPS(state, payload) {
            state.apps = payload;
        }
    },
    actions: {
        refreshMercureCookie() {
            return new Promise(resolve =>
                http('/api/refreshMercureCookie')
                    .then(response => {
                        resolve(response);
                    })
                    .catch((e) => {
                        throw new Error(e);
                    })
            );
        },
        fetchApps({ commit }) {
            return new Promise(resolve =>
                http('/api/apps')
                    .then(response => {
                        commit('SET_APPS', response.data);
                        if(response.headers['link']) {
                            commit('SET_HUB_URL', response.headers['link'].match(/<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/)[1]);
                            commit('SET_LEA_URL', response.headers['link'].match(/<([^>]+)>;\s+rel=(?:lea|"[^"]*lea[^"]*")/)[1]);
                        }
                        resolve(response);
                    })
                    .catch((e) => {
                        throw new Error(e);
                    })
            );
        },
    },
    getters: {
        getHubUrl: state => state.hubUrl,
        getLeaUrl: state => state.leaUrl,
        getApps: state => state.apps
    },
};
