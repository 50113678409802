import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
const messages = {
    en: require('@/locales/en.json'),
    de: require('@/locales/de.json'),
};

const i18n = new VueI18n({
    fallbackLocale: 'de',
    messages, // set locale messages
    silentTranslationWarn: true, // ,
    // missing: (locale, key, vm) => {
    // handle translation missing
    // console.log('missing: ', locale, key)
    // }
});

export default i18n;
