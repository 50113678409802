<template>
    <v-wait :for="forName">
        <template v-slot:waiting>
            <div>
                <v-progress-linear
                    v-if="linear"
                    color="primary"
                    indeterminate
                />
                <v-progress-circular
                    v-else
                    :width="3"
                    color="primary"
                    indeterminate
                />
            </div>
        </template>
    </v-wait>
</template>

<script>
export default {
    props: {
        forName: {
            type: String,
            default: 'componentLoader',
        },
        linear: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
