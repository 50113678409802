<template>
    <div>
        <v-dialog v-model="model" :max-width="calcedMaxWidth" scrollable :persistent="persistent">
            <v-card :loading="loading">
                <v-card-title class="">
                    <h3 class="headline" v-text="$te(title) ? $t(title) : title"/>
                    <template v-if="dismissible">
                        <v-spacer />
                        <div>
                            <v-btn
                                icon
                                @click="close"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-card-title>
                <v-card-text class="">
                    <div class="" v-html="text" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="showCancel"
                        :color="buttonColorCancel"
                        text
                        @click.stop="cancel()"
                        :disabled="loading"
                        >{{ cancelTextModified }}</v-btn
                    >
                    <v-btn
                        v-if="confirmationText"
                        :color="buttonColorConfirm"
                        :disabled="confirmationDisabled || loading"
                        :loading="loading"
                        text
                        @click.stop="confirm()"
                        >{{ confirmationText }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'BaseConfirmationDialog',
    props: {
        title: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        value: {
            type: Boolean,
            default: false,
        },
        showCancel: {
            type: Boolean,
            default: true,
        },
        dismissible: {
            type: Boolean,
            default: false
        },
        confirmationText: {
            type: String,
            default: null,
        },
        cancelText: {
            type: String,
            default: null,
        },
        maxWidth: {
            type: [Number, String],
            default: null,
        },
        buttonColorCancel: {
            type: String,
            default: 'grey',
        },
        buttonColorConfirm: {
            type: String,
            default: 'success',
        },
        confirmationDisabled: {
            type: Boolean,
            default: false
        },
        persistent: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        cancelTextModified() {
            return this.cancelText ? this.cancelText : this.$t('cancel');
        },
        calcedMaxWidth () {
            return this.$vuetify.breakpoint.lgAndUp ? '600' : this.$vuetify.breakpoint.mdAndUp ? '80%' : '100%';
        },
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    methods: {
        confirm() {
            this.$emit('confirm');
            this.model = false;
        },
        cancel() {
            this.$emit('cancel');
            this.model = false;
        },
        close() {
            if (this.dismissible) {
                this.$emit('close');
                this.model = false;
            }
        },
    },
};
</script>
