import { app } from '@/main';
import { getDetails } from '@/utils/auth';

export default {
    namespaced: true,
    state: {
        fetchedLanguage: null,
        currentLanguage: null,
        availableLanguages: ['de', 'en'],
    },
    getters: {
        getFetchedLanguage: state =>
            state.fetchedLanguage ||
            getDetails().preferredLanguage.toLowerCase(),
        getCurrentLanguage: state =>
            state.currentLanguage ||
            getDetails().preferredLanguage.toLowerCase(),
        getAvailableLanguages: state => state.availableLanguages,
    },
    mutations: {
        SET_LANGUAGE(state, payload) {
            state.currentLanguage = payload;
            app.$i18n.locale = payload;
        },
        SET_FETCHED_LANGUAGE(state, payload) {
            state.fetchedLanguage = payload;
            state.currentLanguage = payload;
            app.$i18n.locale = payload;
        },
    },
    actions: {
        updateLanguage: (context, language) => {
            let payload = {
                language
            };
            payload.url = '/api/user/language';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_FETCHED_LANGUAGE', payload);
                }
                if (response.data.token) {
                    context.dispatch('authStore/recreateToken', response.data.token, {
                        root: true,
                    });
                } else {
                    throw new Error('Invalid state');
                }
                return response;
            });
        },
        setFetchedLanguage({ commit }, payload) {
            return new Promise(resolve => {
                commit('SET_FETCHED_LANGUAGE', payload);
                resolve(payload);
            });
        },
        setLanguage({ commit }, payload) {
            return new Promise(resolve => {
                commit('SET_LANGUAGE', payload);
                resolve(payload);
            });
        },
        resetLanguage({ commit, getters }) {
            return new Promise(resolve => {
                commit('SET_FETCHED_LANGUAGE', getters.getFetchedLanguage);
                resolve(getters.getFetchedLanguage);
            });
        },
    },
};
