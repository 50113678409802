import router from '@/router';

export default {
    namespaced: true,
    actions: {
        fetchFilteredRecords: (context, payload = {}) => {
            payload.url = '/api/filter/recordsByFilterAndCompany';
            let route = router && router.history && router.history.current ? router.history.current : null;
            if(route && route.query) {
                if(route.query.filterAlias) {
                    payload.filterAlias = route.query.filterAlias;
                }
                if(route.query.filterValue) {
                    payload.filterValue = route.query.filterValue;
                }
            }
            
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response.data;
            });
        },
        fetchRecordById: (context, recordId) => {
            let payload = {};
            payload.url = '/api/processingActivity/' + recordId;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                return response.data;
            });
        },
        setStatus: (context, payload = {}) => {
            payload.url = '/api/processingActivity/status';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
        delete: (context, payload = {}) => {
            payload.url = '/api/processingActivity/deleteProcessingActivity';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
        persistProcessingActivity: (context, payload = {}) => {
            payload.url = '/api/processingActivity/activity';

            if (
                !payload.leaMaster &&
                payload.language
            ) {
                payload.forcedLanguage = payload.language;
            }
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
    }
};
