<template>
    <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                :color="color"
                @click="go"
                :depressed="depressed"
                :small="small"
                :fab="fab"
                :text="text"
                :icon="icon"
                :right="fab || icon"
                :elevation="elevation"
                :outlined="outlined"
                :loading="loading"
                :disabled="loading"
                class="ma-2"
            >
                <v-icon v-if="fab || icon">mdi-arrow-left</v-icon>
                <span v-if="!fab && !icon">{{ $t('back') }}</span>
            </v-btn>
        </template>
        <span>{{ $t('back') }}</span>
    </v-tooltip>
</template>
<script>
export default {
    name: 'BaseBackButton',
    props: {
        depressed: {
            type: Boolean,
            default: true,
        },
        small: {
            type: Boolean,
            default: true,
        },
        fab: {
            type: Boolean,
            default: true,
        },
        elevation: {
            type: Number,
            default: 2,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        text: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: Boolean,
            default: true,
        },
        route: {
            type: Object,
            default: null,
        },
        color: {
            type: String,
            default: 'white',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        go() {
            if (this.route) {
                this.$router.push(this.route);
            } else {
                this.$router.go(-1);
            }
        },
    },
};
</script>
