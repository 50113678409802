import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        group: [],
        user: [],
        options: null,
    },
    mutations: {
        SET_FILTER_ITEMS(state, filterItems) {
            filterItems.forEach(x => {
                if (x.filterType && !state[x.filterType].find(f => f.id === x.id)) {
                    state[x.filterType].push(x);
                }
            });
        },
        UNSHIFT_FILTER_ITEMS: (state, payload) => {
            state[payload.filterType].unshift(payload.data);
        },
        REMOVE_FILTER_ITEM: (state, payload) => {
            const removedIndex = state[payload.filterType].findIndex(
                x => x.id === payload.filterId
            );
            Vue.delete(state[payload.filterType], removedIndex);
        },
        EDIT_FILTER_ITEM(state, payload) {
            const data = payload.data;
            const itemIndex = state[payload.filterType].findIndex(x => x.id === data.id);
            Vue.set(state[payload.filterType], itemIndex, {
                ...state[payload.filterType][itemIndex],
                ...data,
            });
        },
        SET_FILTER_OPTIONS(state, payload) {
            state.options = payload;
        },
    },
    actions: {
        fetch(context, payload = {}) {
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            const filterType = payload && payload.filterType ? payload.filterType : 'all';
            payload.url = `/api/filter/${filterType}/list`;
            let fetch = force || forcedLanguage;
            if (filterType === 'group' && !context.state.group.length) {
                fetch = true;
            }
            if (filterType === 'user' && !context.state.user.length) {
                fetch = true;
            }
            if (filterType === 'all' && (!context.state.group.length || !context.state.user.length)) {
                fetch = true;
            }
            if (fetch) {
                return context.dispatch('get', payload, { root: true }).then((response) => {
                    if (!response.data.error) {
                        context.commit('SET_FILTER_ITEMS', response.data);
                    }
                    return response;
                });
            }
        },
        fetchById: (context, payload) => {
            payload.url = '/api/admin/filter/savedFilterById/' + payload.id;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                return response;
            });
        },
        fetchOptions(context, payload = {}) {
            payload.url = '/api/admin/filter/filterOptions';
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage && context.state.options && context.state.options.length) {
                return {data: context.getters.getFilterOptions};
            }
            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_FILTER_OPTIONS', response.data);
                }
                return response;
            });
        },
        create: (context, payload = {}) => {
            const filterType = payload && payload.filterType === 'group' ? 'group' : 'user';
            payload.url = `/api/admin/filter/${filterType}/create`;
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('UNSHIFT_FILTER_ITEMS', {
                        filterType: filterType,
                        data: response.data,
                    });
                }
                return response;
            });
        },
        edit: (context, payload = {}) => {
            const filterType = payload && payload.filterType === 'group' ? 'group' : 'user';
            payload.url = `/api/admin/filter/${filterType}/edit`;
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('EDIT_FILTER_ITEM', {
                        filterType: filterType,
                        data: response.data,
                    });
                }
                return response;
            });
        },
        delete: (context, payload = {}) => {
            const filterType = payload && payload.filterType === 'group' ? 'group' : 'user';
            payload.url = `/api/admin/filter/${filterType}/delete`;
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    const data = {
                        response,
                        filterType: filterType,
                        filterId: payload.id,
                    };
                    context.commit('REMOVE_FILTER_ITEM', data);
                }
                return response;
            });
        },
    },
    getters: {
        getGroupFilter: state => state.group.sort((a,b) => {
            if(a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
            if(a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
            return 0;
        }),
        getUserFilter: state => state.user.sort((a,b) => {
            if(a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
            if(a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
            return 0;
        }),
        getAllFilter: (state, getters) => {
            const arrHeaderUser = [{ header: 'vvtList.filterColumn.userFilter' }];
            const arrHeaderGroup = [{ header: 'vvtList.filterColumn.groupFilter' }];
            if (!getters.getUserFilter.length) {
                return [...arrHeaderGroup, ...getters.getGroupFilter];
            }
            return [...arrHeaderUser, ...getters.getUserFilter, ...arrHeaderGroup, ...getters.getGroupFilter];
        },
        findById: (state, getters) => id => getters.getAllFilter.find(x => x.id === id),
        getFilterOptions: state => state.options
    },
};
