import Vue from 'vue';
import Router from 'vue-router';
import { hasRole, isAuthenticated, deleteToken } from './utils/auth';

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: () => {
                if (process.env.VUE_APP_SSO !== 'true') {
                    return import(/* webpackChunkName: "login" */ './views/Login');
                }
            },
            meta: {
                layout: 'auth',
            },
        },
        {
            path: '/hiddenIFrame',
            name: 'HiddenIFrame',
            redirect: { name: 'Login', query: {redirect: '/hiddenIFrameResponse'} }
        },
        {
            path: '/',
            redirect: { name: 'Home' }
        },
        {
            path: '/hiddenIFrameResponse',
            name: 'HiddenIFrameResponse',
            component: () => import(/* webpackChunkName: "hiddenIFrameResponse" */ './components/layout/HiddenIFrameResponse.vue'),
            meta: { requiresAuth: true },
        },
        {
            path: '/download/:type/:uuid/:filename',
            name: 'Download',
            component: () => import(/* webpackChunkName: "download" */ './views/Download.vue'),
            props(route) {
                const type = route.params.type;
                const uuid = route.params.uuid;
                const filename = route.params.filename;
                return {
                    type,
                    uuid,
                    filename
                };
            },
            meta: { requiresAuth: true },
        },
        {
            path: '/dashboard/:filter?',
            name: 'Home',
            component: () => import(/* webpackChunkName: "Home" */ './views/Dashboard'),
            props(route) {
                const filter = !route.params && !route.params.filter ? null : parseInt(route.params.filter);
                return {
                    filter
                };
            },
            meta: {
                requiresAuth: true,
                appBarElevateOnScroll: false
            },
        },
        {
            path: '/activityStream',
            name: 'ActivityStream',
            component: () =>
                import(
                    /* webpackChunkName: "ActivityStream" */ './views/ActivityStream.vue'
                ),
            meta: { requiresAuth: true, role: 'ROLE_READER' },
        },
        {
            path: '/vvt-list/:filterId?/:group?/:groupId?',
            name: 'VvtList',
            component: () =>
                import(
                    /* webpackChunkName: "vvtList" */ './views/vvt/VvtList.vue'
                ),
            props(route) {
                return {
                    filterId: route.params && route.params.filterId ? route.params.filterId : 'all',
                    group: route.params && route.params.group && route.params.group === 'domain' ? 'domain' : 'company',
                    groupId: route.params && route.params.groupId ? route.params.groupId : null
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_READER' },
        },
        {
            path: '/vvt/selectTemplate/:type?/:id?',
            name: 'VvtProcessingActivityPool',
            component: () =>
                import(
                    /* webpackChunkName: "vvtProcessingActivityPool" */ './views/vvt/VvtProcessingActivityPool.vue'
                ),
            props(route) {
                return {
                    companyGroupTemplate: false,
                    type: route.params && route.params.type ? route.params.type : null,
                    id: route.params && route.params.id ? route.params.id : null
                }
            },
            meta: { requiresAuth: true, role: 'ROLE_READER' },
        },
        {
            path: '/vvt/create',
            name: 'VvtCreateProcessingActivity',
            component: () =>
                import(
                    /* webpackChunkName: "vvtCreateProcessingActivity" */ './views/vvt/VvtCreateProcessingActivity.vue'
                ),
            meta: {
                requiresAuth: true,
                role: 'ROLE_CREATOR'
            },
        },
        {
            path: '/vvt/edit/:id',
            name: 'VvtEditProcessingActivity',
            component: () =>
                import(
                    /* webpackChunkName: "vvtEditProcessingActivity" */ './views/vvt/VvtEditProcessingActivity.vue'
                ),
            props(route) {
                const id = route.params.id || '';
                return {
                    id,
                };
            },
            meta: {
                requiresAuth: true,
                role: 'ROLE_READER'
            },
        },
        {
            path: '/community/list/:category?/:id?',
            name: 'CommunityList',
            component: () =>
                import(
                    /* webpackChunkName: "CommunityList" */ './views/community/CommunityList.vue'
                ),
            props(route) {
                const category = route.params.category || '';
                const id = parseInt(route.params.id) || '';
                return {
                    category,
                    id,
                };
            },
            meta: { requiresAuth: true },
        },
        {
            path: '/settings',
            name: 'Settings',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsIndex" */ './views/settings/index.vue'
                ),
            meta: { requiresAuth: true },
        },
        {
            path: '/settings/changePassword',
            name: 'SettingsChangePassword',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsChangePassword" */ './views/settings/ChangePassword.vue'
                ),
            meta: { requiresAuth: true },
        },
        {
            path: '/settings/changeLanguage',
            name: 'SettingsChangeLanguage',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsChangeLanguage" */ './views/settings/ChangeLanguage.vue'
                ),
            meta: { requiresAuth: true },
        },
        {
            path: '/settings/tools/:action?/:id?',
            name: 'SettingsTools',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsTools" */ './views/settings/Tools.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/securityControls/:action?/:id?',
            name: 'SettingsSecurityControls',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsSecurityControls" */ './views/settings/SecurityControls.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/groupsOfPerson/:action?/:id?',
            name: 'SettingsGroupsOfPerson',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsGroupsOfPerson" */ './views/settings/GroupsOfPerson.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/personalData/:action?/:id?',
            name: 'SettingsPersonalData',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsPersonalData" */ './views/settings/PersonalData.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/personalDataCategories/:action?/:id?',
            name: 'SettingsPersonalDataCategories',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsPersonalDataCategories" */ './views/settings/PersonalDataCategories.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/deletionPractise/:action?/:id?',
            name: 'SettingsDeletionPractise',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsDeletionPractise" */ './views/settings/DeletionPractise.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/internalKeywords/:action?/:id?',
            name: 'SettingsInternalKeywords',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsInternalKeywords" */ './views/settings/InternalKeywords.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/internalRecipientCategories/:action?/:id?',
            name: 'SettingsInternalRecipientCategories',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsInternalRecipientCategories" */ './views/settings/InternalRecipientCategories.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/externalRecipientCategories/:action?/:id?',
            name: 'SettingsExternalRecipientCategories',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsExternalRecipientCategories" */ './views/settings/ExternalRecipientCategories.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/record-filter/:filterType/:action?/:id?',
            name: 'SettingsRecordFilter',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsRecordFilter" */ './views/settings/RecordFilter.vue'
                ),
            props(route) {
                const filterType = route.params.filterType || 'user';
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    filterType,
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_READER' },
        },
        {
            path: '/settings/appropriateSafeguards/:action?/:id?',
            name: 'SettingsAppropriateSafeguards',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsAppropriateSafeguards" */ './views/settings/AppropriateSafeguards.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/groupHelp/:action?/:id?',
            name: 'SettingsCompanyGroupHelp',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsCompanyGroupHelp" */ './views/settings/CompanyGroupHelp.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/companies/:action?/:id?',
            name: 'SettingsCompanyList',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsCompanyList" */ './views/settings/CompanyList.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/tom/:action?/:id?',
            name: 'SettingsCompanyTom',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsCompanyTom" */ './views/settings/CompanyTom.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/staff/:action?/:id?',
            name: 'SettingsStaff',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsStaff" */ './views/settings/Staff.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/departments/:action?/:id?/:childAction?/:childId?',
            name: 'SettingsCompanyDepartments',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsCompanyDepartments" */ './views/settings/CompanyDepartments.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                const childAction = route.params.childAction || '';
                const childId = route.params.childId || '';
                return {
                    action,
                    id,
                    childAction,
                    childId,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/vvt/group-templates/list/:category?',
            name: 'SettingsVvtGroupTemplatesList',
            component: () =>
                import(
                    /* webpackChunkName: "vvtTemplateList" */ './views/settings/templates/VvtTemplateList.vue'
                ),
            props(route) {
                const category = !route.params && !route.params.category ? 'all' : parseInt(route.params.category);
                return {
                    category
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/vvt/group-templates/selectTemplate/:type?/:id?',
            name: 'SettingsVvtGroupTemplatesSelectFromTemplate',
            component: () =>
                import(
                    /* webpackChunkName: "vvtProcessingActivityPool" */ './views/vvt/VvtProcessingActivityPool.vue'
                ),
            props(route) {
                return {
                    companyGroupTemplate: true,
                    type: route.params && route.params.type ? route.params.type : null,
                    id: route.params && route.params.id ? route.params.id : null
                }
            },
            meta: { requiresAuth: true },
        },
        {
            path: '/settings/vvt/group-templates/create',
            name: 'SettingsVvtGroupTemplatesCreate',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsVvtGroupTemplatesCreate" */ './views/settings/templates/VvtTemplateCreate.vue'
                ),
            meta: {
                requiresAuth: true,
                role: 'ROLE_TENANT_ADMIN'
            },
        },
        {
            path: '/settings/vvt/group-templates/edit/:id',
            name: 'SettingsVvtGroupTemplatesEdit',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsVvtGroupTemplatesEdit" */ './views/settings/templates/EditTemplate.vue'
                ),
            props(route) {
                const id = route.params.id || '';
                return {
                    id,
                };
            },
            meta: {
                requiresAuth: true,
                role: 'ROLE_TENANT_ADMIN'
            },
        },
        {
            path: '/settings/companyGroup',
            name: 'SettingsCompanyGroup',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsCompanyGroup" */ './views/settings/SettingsCompanyGroup.vue'
                ),
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/notification/:action?/:id?',
            name: 'SettingsCompanyGroupNotification',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsCompanyGroupNotification" */ './views/settings/CompanyGroupNotification.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/domains/:action?/:id?',
            name: 'SettingsDomains',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsDomains" */ './views/settings/Domains.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/settings/vendor/:action?/:id?',
            name: 'SettingsVendor',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsVendor" */ './views/settings/Vendor.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_TENANT_ADMIN' },
        },
        {
            path: '/imprint',
            name: 'Imprint',
            component: () =>
                import(/* webpackChunkName: "Imprint" */ './views/Imprint.vue'),
            meta: { requiresAuth: false },
        },
        {
            path: '/privacy',
            name: 'Privacy',
            component: () =>
                import(/* webpackChunkName: "Privacy" */ './views/Privacy.vue'),
            meta: { requiresAuth: false },
        },
        {
            path: '/admin',
            name: 'Admin',
            component: () =>
                import(
                    /* webpackChunkName: "AdminIndex" */ './views/admin/index.vue'
                ),
            meta: { requiresAuth: true, role: 'ROLE_MASTER_EDITOR' },
        },
        {
            path: '/admin/vvt/master',
            name: 'AdminVvtMaster',
            component: () =>
                import(
                    /* webpackChunkName: "AdminVvtMaster" */ './views/admin/VvtMaster.vue'
                ),
            meta: { requiresAuth: true, role: 'ROLE_MASTER_EDITOR' },
        },
        {
            path: '/admin/vvt/master/create',
            name: 'AdminVvtCreateMaster',
            component: () =>
                import(
                    /* webpackChunkName: "AdminVvtCreateMaster" */ './views/admin/CreateMasterTemplate.vue'
                ),
            meta: { 
                requiresAuth: true, 
                role: 'ROLE_MASTER_EDITOR'
            },
        },
        {
            path: '/admin/vvt/master/edit/:id',
            name: 'AdminVvtEditMaster',
            component: () =>
                import(
                    /* webpackChunkName: "AdminVvtEditMaster" */ './views/admin/EditMasterTemplate.vue'
                ),
            props(route) {
                const id = route.params.id || '';
                return {
                    id,
                };
            },
            meta: { 
                requiresAuth: true,
                role: 'ROLE_MASTER_EDITOR'
            },
        },
        {
            path: '/admin/blacklist/:action?/:id?/:childAction?/:childId?',
            name: 'AdminBlacklist',
            component: () =>
                import(
                    /* webpackChunkName: "AdminBlacklist" */ './views/admin/Blacklist.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                const childAction = route.params.childAction || '';
                const childId = route.params.childId || '';
                return {
                    action,
                    id,
                    childAction,
                    childId,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_MASTER_EDITOR' },
        },
        {
            path: '/admin/community/:id?',
            name: 'AdminCommunityList',
            component: () =>
                import(
                    /* webpackChunkName: "AdminCommunityList" */ './views/admin/CommunityList.vue'
                ),
            props(route) {
                const id = parseInt(route.params.id) || '';
                return {
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_MASTER_EDITOR' },
        },
        {
            path: '/admin/legitimate-interest/:action?/:id?',
            name: 'AdminLegitimateInterest',
            component: () =>
                import(
                    /* webpackChunkName: "AdminLegitimateInterest" */ './views/admin/LegitimateInterest.vue'
                ),
            props(route) {
                const action = route.params.action || '';
                const id = route.params.id || '';
                return {
                    action,
                    id,
                };
            },
            meta: { requiresAuth: true, role: 'ROLE_MASTER_EDITOR' },
        },
        {
            path: '/logout',
            name: 'Logout',
        },
        // Fallback route when page not found
        {
            path: '*',
            name: 'Error',
            component: () =>
                import(/* webpackChunkName: "Error" */ './views/Error'),
        },
    ],
});

router.beforeEach((to, from, next) => {    
    if (to.name === 'Login' && !isAuthenticated() && process.env.VUE_APP_SSO === 'true') {      
        let href = `${process.env.VUE_APP_BASE_URL}/login`;
        if (to.query && to.query.redirect) {
            let redirect = encodeURIComponent(to.query.redirect)
            href += `?q=${redirect}`
        }
        location.href = href;
    } else if (to.name === 'Login' && isAuthenticated()) {
        next({
            path: '/',
        });
    } else if (to.name === 'Logout') {
        if (isAuthenticated()) {
            deleteToken();
        }
        if (process.env.VUE_APP_SSO === 'true') {
            location.href = '/saml/logout';
        } else {
            next(false);
            router.go({
                path: '/login',
            });
        }
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated()) {
            next({
                path: '/login',
                query: { redirect: to.fullPath },
            });
        } else if (to.matched.some(record => Object.prototype.hasOwnProperty.call(record.meta, 'role') && !hasRole(record.meta.role))) {
            next({
                path: '/',
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
