import decode from 'jwt-decode';
import http from '@/utils/axios';

const JWT_TOKEN = 'JWT_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const STAY_LOGGED_IN = 'STAY_LOGGED_IN';

export function storeToken(token, stayLoggedIn, refreshToken) {
    decode(token);
    localStorage.setItem(JWT_TOKEN, token);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    localStorage.setItem(STAY_LOGGED_IN, stayLoggedIn);
}

export function hasRole(roleName) {
    if (getDetails() === null || !getDetails().roles || !getDetails().roles.length) {
        return false;
    }
    if (typeof roleName === 'string') {
        return Object.values(getDetails().roles).includes(roleName);
    }
    if (roleName.constructor === Array) {
        return Object.values(getDetails().roles).some( r => roleName.includes(r));
    }
}

export function hasFeature(featureName) {
    return (
        getDetails() !== null &&
        getDetails().features &&
        Object.values(getDetails().features).includes(featureName)
    );
}

export function getDetails() {
    if (getToken()) {
        return decode(getToken());
    }
    return null;
}

export function getRoles() {
    let x = getDetails();
    if (x && x.roles) {
        return x.roles;
    }
    return [];
}

export function getToken() {
    return localStorage.getItem(JWT_TOKEN);
}
export function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
}

export function isAuthenticated() {
    if (
        (!getToken() || hasExpired(getToken())) &&
        `${localStorage.getItem(STAY_LOGGED_IN)}` === 'true'
    ) {
        return http
            .post('/api/token/refresh', {
                refresh_token: getRefreshToken(),
            })
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    storeToken(
                        res.data.token,
                        localStorage.getItem(STAY_LOGGED_IN),
                        res.data.refresh_token
                    );
                    http.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
                }
            });
    }
    return !!getToken() && !hasExpired(getToken());
}

export function deleteToken() {
    localStorage.removeItem(JWT_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(STAY_LOGGED_IN);
    delete http.defaults.headers.common.Authorization;
}

export function getTokenExpirationDate(encodedToken) {
    const token = decode(encodedToken);
    if (!token.exp) {
        return null;
    }

    const date = new Date(0);

    date.setUTCSeconds(token.exp);
    return date;
}
export function hasExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}

