import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        items: [],
    },
    getters: {
        getItems: state => state.items,
        getItem: state => item =>
            state.items[item] ? state.items[item] : null,
    },
    mutations: {
        SET_ITEM(state, payload) {
            Vue.set(state.items, payload.property, payload.data);
        },
    },
    actions: {
        set({ commit }, payload) {
            commit('SET_ITEM', payload);
        },
    },
};
