export default {
    namespaced: true,
    state: {
        messages: []
    },
    mutations: {
        ADD_MESSAGE(state, payload) {
            state.messages.push(payload.data);
        },
        CLEAR_MESSAGES(state) {
            state.messages = [];
        },
        CLEAR_TOPIC_MESSAGES(state, topic) {
            state.messages = state.messages.filter(x => x.topic.substring(0, topic.length) !== topic);
        }
    },
    actions: {
        add(context, payload) {
            new Promise(resolve => {

                const prefix = context.rootGetters['apps/getLeaUrl'];

                const testTopic = prefix + '/test/';
                const storeActionTopic = prefix + '/storeAction/';
                const addUserTaskTopic = prefix + '/addUserTask/';
                const updateUserTaskStateTopic = prefix + '/updateUserTaskState/';
                const addConsultantTaskTopic = prefix + '/addConsultantTask/';
                const updateConsultantTaskStateTopic = prefix + '/updateConsultantTaskState/';
                const workflowUpdateTopic = prefix + '/workflowUpdate/';
                const processingActivityUpdateTopic = prefix + '/processingActivityUpdate/';
                
                if(Object.prototype.hasOwnProperty.call(payload.data, 'task') && (
                    payload.topic.substring(0, addUserTaskTopic.length) === addUserTaskTopic ||
                    payload.topic.substring(0, testTopic.length) === testTopic
                )) {
                    context.dispatch('dashboard/addContactTask', payload.data.task, { root: true });
                }
                
                else if(Object.prototype.hasOwnProperty.call(payload.data, 'task') && payload.topic.substring(0, updateUserTaskStateTopic.length) === updateUserTaskStateTopic) {
                    context.dispatch('dashboard/updateContactTaskState', payload.data.task, { root: true });
                }

                else if(payload.topic.substring(0, storeActionTopic.length) === storeActionTopic) {
                    let action = payload.data.action;
                    let data = payload.data.data;
                    context.dispatch(action, data, { root: true });
                }
                
                else if(payload.topic.substring(0, addConsultantTaskTopic.length) === addConsultantTaskTopic) {
                    context.dispatch('dashboard/addConsultantTask', payload.data, { root: true });
                }
                
                else if(payload.topic.substring(0, updateConsultantTaskStateTopic.length) === updateConsultantTaskStateTopic) {
                    context.dispatch('dashboard/updateConsultantTaskState', payload.data, { root: true });
                }

                else if(payload.topic.substring(0, workflowUpdateTopic.length) === workflowUpdateTopic) {
                    context.commit('ADD_MESSAGE', payload);
                }

                else if(payload.topic.substring(0, processingActivityUpdateTopic.length) === processingActivityUpdateTopic) {
                    context.commit('ADD_MESSAGE', payload);
                }

                else {
                    context.commit('ADD_MESSAGE', payload);
                }
                
                resolve();
            })
        },
        clearAllMessages({ commit }) {
            new Promise(resolve => {
                commit('CLEAR_MESSAGES');
                resolve();
            })
        },
        clearTopicMessages(context, topic) {
            new Promise(resolve => {
                const prefix = context.rootGetters['apps/getLeaUrl'];
                context.commit('CLEAR_TOPIC_MESSAGES', prefix + '/' + topic + '/');
                resolve();
            })
        },
    },
    getters: {
        getMessages: state => state.messages,
        getMercureActivityChangedNotifications: (state, getters, rootState, rootGetters) => {
            const topic = rootGetters['apps/getLeaUrl'] + '/processingActivityUpdate/';
            return state.messages.filter(x => x.topic.substring(0, topic.length) === topic);
        },
        getMercureActivityChangedByOtherNotifications: (state, getters, rootState, rootGetters) => (entityId, userId) => {
            const topic = rootGetters['apps/getLeaUrl'] + '/processingActivityUpdate/';
            return state.messages.filter(x => x.topic.substring(0, topic.length) === topic && x.entityId === entityId && x.updatedBy !== userId);
        },
        getMercureWorkflowUpdateNotifications: (state, getters, rootState, rootGetters) => (entityId, userId) => {
            const topic = rootGetters['apps/getLeaUrl'] + '/workflowUpdate/';
            return state.messages.filter(x => x.topic.substring(0, topic.length) === topic && x.entityId === entityId && x.updatedBy !== userId);
        }
    },
};
