import decode from 'jwt-decode';
import http from '@/utils/axios';

const JWT_TOKEN = 'USER_INFO';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const STAY_LOGGED_IN = 'STAY_LOGGED_IN';

function getCookieValue(cookieName) {
    let cookie = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
    return cookie ? cookie.pop() : '';
}

function retireCookie(cookieName) {
    let cookie = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
    cookie = cookie ? cookie.pop() : null;
    if (cookie !== null) {
        document.cookie = `${cookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
    }
}

export function storeToken(token) {
    decode(token);
    localStorage.removeItem(JWT_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.setItem(STAY_LOGGED_IN, false);
}

export function hasRole(roleName) {
    if (getDetails() === null || !getDetails().roles || !getDetails().roles.length) {
        return false;
    }
    if (typeof roleName === 'string') {
        return Object.values(getDetails().roles).includes(roleName);
    }
    if (roleName.constructor === Array) {
        return Object.values(getDetails().roles).some( r => roleName.includes(r));
    }
}

export function hasFeature(featureName) {
    return (
        getDetails() !== null &&
        getDetails().features &&
        Object.values(getDetails().features).includes(featureName)
    );
}

export function getDetails() {
    if (getToken()) {
        return decode(getToken());
    }
    return null;
}

export function getRoles() {
    let x = getDetails();
    if (x && x.roles) {
        return x.roles;
    }
    return [];
}

export function getToken() {
    return getCookieValue(JWT_TOKEN);
}
export function getRefreshToken() {
    return getCookieValue(REFRESH_TOKEN);
}

export function isAuthenticated() {
    return !!getToken() && !hasExpired(getToken());
}

export function deleteToken() {
    retireCookie(JWT_TOKEN);
    localStorage.removeItem(JWT_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(STAY_LOGGED_IN);
    delete http.defaults.headers.common.Authorization;
}

export function getTokenExpirationDate(encodedToken) {
    const token = decode(encodedToken);
    if (!token.exp) {
        return null;
    }

    const date = new Date(0);

    date.setUTCSeconds(token.exp);
    return date;
}
export function hasExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}
