import * as authJwt from './authJwt'
import * as authSaml from './authSaml'

export function storeToken(token, stayLoggedIn, refreshToken) {
    if (process.env.VUE_APP_SSO === 'true') {
        return authSaml.storeToken(token, stayLoggedIn, refreshToken);
    } else {
        return authJwt.storeToken(token, stayLoggedIn, refreshToken);
    }
}

export function hasRole(roleName) {
    if (process.env.VUE_APP_SSO === 'true') {
        return authSaml.hasRole(roleName);
    } else {
        return authJwt.hasRole(roleName);
    }
}

export function hasFeature(featureName) {
    if (process.env.VUE_APP_SSO === 'true') {
        return authSaml.hasFeature(featureName);
    } else {
        return authJwt.hasFeature(featureName);
    }
}

export function getDetails() {
    if (process.env.VUE_APP_SSO === 'true') {
        return authSaml.getDetails();
    } else {
        return authJwt.getDetails();
    }
}

export function getRoles() {
    if (process.env.VUE_APP_SSO === 'true') {
        return authSaml.getRoles();
    } else {
        return authJwt.getRoles();
    }
}

export function getToken() {
    if (process.env.VUE_APP_SSO === 'true') {
        return authSaml.getToken();
    } else {
        return authJwt.getToken();
    }
}
export function getRefreshToken() {
    if (process.env.VUE_APP_SSO === 'true') {
        return authSaml.getRefreshToken();
    } else {
        return authJwt.getRefreshToken();
    }
}

export function isAuthenticated() {
    if (process.env.VUE_APP_SSO === 'true') {
        return authSaml.isAuthenticated();
    } else {
        return authJwt.isAuthenticated();
    }
}

export function deleteToken() {
    if (process.env.VUE_APP_SSO === 'true') {
        return authSaml.deleteToken();
    } else {
        return authJwt.deleteToken();
    }
}

export function getTokenExpirationDate(encodedToken) {
    if (process.env.VUE_APP_SSO === 'true') {
        return authSaml.getTokenExpirationDate(encodedToken);
    } else {
        return authJwt.getTokenExpirationDate(encodedToken);
    }
}
export function hasExpired(token) {
    if (process.env.VUE_APP_SSO === 'true') {
        return authSaml.hasExpired(token);
    } else {
        return authJwt.hasExpired(token);
    }
}
