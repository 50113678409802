export default {
    namespaced: true,
    state: {
        categoryList: [],
    },
    getters: {
        getCategoryList: state => state.categoryList,
    },
    mutations: {
        SET_CATEGORY_LIST(state, categoryList) {
            state.categoryList = categoryList;
        },
    },
    actions: {
        fetchCategoryList(context, payload = {}) {
            payload.url = '/api/legitimateInterest/categoryList';
            const disableStoreUpdate = payload.disableStoreUpdate || false;
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage) {
                return {data: context.getters.getItems};
            }
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!disableStoreUpdate && !response.data.error) {
                    context.commit('SET_CATEGORY_LIST', response.data);
                }
                return response;
            });
        },
        clearItems({ commit }) {
            commit('SET_CATEGORY_LIST', []);
        },
    },
};
