export default {
    namespaced: true,
    state: {
        templates: [],
        template: null,
    },
    getters: {
        getTemplate: state => state.template,
        getTemplates: state => state.templates,
    },
    mutations: {
        SET_TEMPLATES(state, templates) {
            state.templates = templates;
        },
        SET_TEMPLATE(state, template) {
            state.template = template;
        },
    },
    actions: {
        fetchLeaMaster (context, payload) {
            payload.url = '/api/templates/lea-master';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
        fetchSelection(context, payload) {
            payload.url = '/api/templates/selection';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_TEMPLATES', response.data);
                }
                return response;
            });
        },
        fetchById: (context, payload) => {
            payload.url = '/api/templateById/' + payload.templateId;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_TEMPLATE', response.data);
                }
                return response;
            });
        },
    },
};
