// see: http://www.lukasfeiler.com/presentations/Feiler_Die_69_Oeffnungsklauseln_der%20DS-GVO.pdf
export default {
    namespaced: true,
    state: {
        items: [],
        itemsFetched: false
    },
    getters: {
        description: state => state.create,
        getItems: state => state.items,
        getItemsWithoutCategory: state => {
            let items = [];
            state.items.forEach(x => {
                items = [...items, ...x.items];
            });
            return items;
        },
        getCategoriesWithItems: (state, getters) => {
            const flatItems = [];
            getters.getItems.map(cat => {
                if (cat.useableForProcess) {
                    flatItems.push({
                        header: cat.title,
                    });
                    cat.items.map(item => {
                        flatItems.push(item);
                    });
                }
            });
            return flatItems;
        },
    },
    mutations: {
        SET_ITEMS(state, items) {
            state.items = items;
        },
        SET_FETCHED(state, reset = false) {
            state.itemsFetched = !reset;
        },
    },
    actions: {
        fetch(context, payload = {}) {
            payload.url = '/api/flexibilityClause';
            const disableStoreUpdate = payload.disableStoreUpdate || false;
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage && context.state.itemsFetched) {
                return {data: context.getters.getItems};
            }
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!disableStoreUpdate && !response.data.error) {
                    context.commit('SET_FETCHED');
                    context.commit('SET_ITEMS', response.data);
                }
                return response;
            });
        },
        clearItems({ commit }) {
            commit('SET_FETCHED', true);
            commit('SET_ITEMS', []);
        },
    },
};
