import Vue from 'vue';
import http from '@/utils/axios';
import { groupBy, chain } from 'lodash';
import { dateTypes, parseISO, formatRelativeGroup } from '@/utils/dateFns';

export default {
    namespaced: true,
    state: {
        kpiFetched: false,
        companyCount: 0,
        vvtRecordCount: 0,
        vvtRecordDraftCount: 0,
        vvtRecordReviewCount: 0,
        vvtRecordApprovalCount: 0,
        vvtRecordReleaseCount: 0,
        vvtRecordFinalisedCount: 0,
        vvtRecordArchivedCount: 0,
        vvtRecordLowOrMediumRiskCount: 0,
        vvtRecordHighOrVeryHighRiskCount: 0,
        vvtRecordDpiaMissingCount: 0,
        vvtRecordDpiaCompletedCount: 0,
        vvtRecordDataFlow: [],
        vendorDpaCompletedCount: 0,
        vendorDpaMissingCount: 0,
        vendorDpaThirdCountryWithoutSafeguardCount: 0,
        activityStreamItems: [],
        contactTasks: [],
        consultantTasks: []
    },
    getters: {
        companyCount: state => parseInt(state.companyCount),
        vvtRecordCount: state => parseInt(state.vvtRecordCount),
        vvtRecordDraftCount: state => parseInt(state.vvtRecordDraftCount),
        vvtRecordReviewCount: state => parseInt(state.vvtRecordReviewCount),
        vvtRecordApprovalCount: state => parseInt(state.vvtRecordApprovalCount),
        vvtRecordReleaseCount: state => parseInt(state.vvtRecordReleaseCount),
        vvtRecordFinalisedCount: state => parseInt(state.vvtRecordFinalisedCount),
        vvtRecordArchivedCount: state => parseInt(state.vvtRecordArchivedCount),
        vvtRecordLowOrMediumRiskCount: state => parseInt(state.vvtRecordLowOrMediumRiskCount),
        vvtRecordHighOrVeryHighRiskCount: state => parseInt(state.vvtRecordHighOrVeryHighRiskCount),
        vvtRecordDpiaMissingCount: state => parseInt(state.vvtRecordDpiaMissingCount),
        vvtRecordDpiaCompletedCount: state => parseInt(state.vvtRecordDpiaCompletedCount),
        vvtRecordDataFlow: state => state.vvtRecordDataFlow,
        vendorDpaCompletedCount: state => parseInt(state.vendorDpaCompletedCount),
        vendorDpaMissingCount: state => parseInt(state.vendorDpaMissingCount),
        vendorDpaThirdCountryWithoutSafeguardCount: state => parseInt(state.vendorDpaThirdCountryWithoutSafeguardCount),
        getGroupedActivityStreamItems: state => {
            const groupedActivity = groupBy(state.activityStreamItems, item =>
                formatRelativeGroup(
                    parseISO(item.date),
                    new Date(),
                    'week',
                    dateTypes.fullDate
                )
            );
            return chain(groupedActivity)
                .map((items, group) => ({
                    group,
                    items,
                }))
                .value();
        },
        getContactTask: state => state.contactTasks,
        getConsultantTaskItems: state => state.consultantTasks,
        getConsultantTaskCount: state => state.consultantTasks.length,
    },
    mutations: {
        SET_KPI_FETCHED(state) {
            state.kpiFetched = true;
        },
        INVALIDATE_KPI(state) {
            state.kpiFetched = false;
        },
        SET_COUNT(state, payload) {
            state[payload.prop] = payload.data;
        },
        ADD_COUNT(state, payload) {
            let amount = payload.data ? payload.data : 1;
            state[payload.prop] = state[payload.prop] + amount;
        },
        REDUCE_COUNT(state, payload) {
            let amount = payload.data ? payload.data : 1;
            state[payload.prop] = state[payload.prop] - amount;
        },

        SET_ACTIVITY_STREAM(state, payload) {
            state.activityStreamItems = payload;
        },
        SET_CONSULTANT_TASKS(state, payload) {
            state.consultantTasks = payload;
        },
        ADD_CONSULTANT_TASK(state, payload) {
            const itemIndex = state.consultantTasks.findIndex(
                x => x.id === payload.id
            );
            if (itemIndex === -1 && payload.status !== 'closed') {
                state.consultantTasks.unshift(payload);
            }
        },
        CHANGE_CONSULTANT_TASK_STATE(state, payload) {
            const itemIndex = state.consultantTasks.findIndex(
                x => x.id === payload.id
            );
            if (itemIndex > -1) {
                if (payload.status === 'deleted') {
                    Vue.delete(state.consultantTasks, itemIndex);
                } else {
                    Vue.set(
                        state.consultantTasks[itemIndex],
                        'status',
                        payload.status
                    );
                }
            }
        },
        SET_CONTACT_TASKS(state, payload) {
            state.contactTasks = payload;
        },
        ADD_CONTACT_TASK(state, payload) {
            const itemIndex = state.contactTasks.findIndex(
                x => x.id === payload.id
            );
            if (itemIndex === -1) {
                state.contactTasks.unshift(payload);
            }
        },
        CHANGE_CONTACT_TASK_STATE(state, payload) {
            const itemIndex = state.contactTasks.findIndex(
                x => x.id === payload.id
            );
            if (itemIndex > -1) {
                if (payload.status === 'deleted') {
                    Vue.delete(state.contactTasks, itemIndex);
                } else {
                    Vue.set(
                        state.contactTasks[itemIndex],
                        'status',
                        payload.status
                    );
                }
            }
        },
        SET_DATA_FLOW(state, payload) {
            state.vvtRecordDataFlow = payload;
        }
    },
    actions: {
        fetchKpi: (context, payload) => {
            if (!context.state.kpiFetched || (payload && payload.force)) {
                return http('/api/dashboard/kpi')
                    .then(response => {
                            context.commit('SET_COUNT', {prop: 'companyCount', data: response.data.companyCount});
                            context.commit('SET_COUNT', {prop: 'vvtRecordCount', data: response.data.recordsCount});
                            context.commit('SET_COUNT', {prop: 'vvtRecordDraftCount', data: response.data.recordsDraftCount});
                            context.commit('SET_COUNT', {prop: 'vvtRecordReviewCount', data: response.data.recordsReviewCount});
                            context.commit('SET_COUNT', {prop: 'vvtRecordApprovalCount', data: response.data.recordsApprovalCount});
                            context.commit('SET_COUNT', {prop: 'vvtRecordReleaseCount', data: response.data.recordsReleaseCount});
                            context.commit('SET_COUNT', {prop: 'vvtRecordFinalisedCount', data: response.data.recordsFinalisedCount});
                            context.commit('SET_COUNT', {prop: 'vvtRecordArchivedCount', data: response.data.recordsArchivedCount});
                            context.commit('SET_COUNT', {prop: 'vvtRecordLowOrMediumRiskCount', data: response.data.recordsLowOrMediumRiskCount});
                            context.commit('SET_COUNT', {prop: 'vvtRecordHighOrVeryHighRiskCount', data: response.data.recordsHighOrVeryHighRiskCount});
                            context.commit('SET_COUNT', {prop: 'vvtRecordDpiaCompletedCount', data: response.data.recordsDpiaCompletedCount});
                            context.commit('SET_COUNT', {prop: 'vvtRecordDpiaMissingCount', data: response.data.recordsDpiaMissingCount});

                            context.commit('SET_COUNT', {prop: 'vendorDpaCompletedCount', data: response.data.vendorDpaCompletedCount});
                            context.commit('SET_COUNT', {prop: 'vendorDpaMissingCount', data: response.data.vendorDpaMissingCount});
                            context.commit('SET_COUNT', {prop: 'vendorDpaThirdCountryWithoutSafeguardCount', data: response.data.vendorDpaThirdCountryWithoutSafeguardCount});

                            context.commit('SET_DATA_FLOW', response.data.dataFlow);
                            context.commit('SET_KPI_FETCHED');
                        })
                        .catch(() => {
                            throw new Error('Network exception');
                        });
            }
        },
        setCount: (context, payload) => context.commit('SET_COUNT', {prop: payload.prop, data: payload.value}),
        invalidateKpi: (context) => context.commit('INVALIDATE_KPI'),
        addCompanyCount: (context, payload) => context.commit('ADD_COUNT', {prop: 'companyCount', data: payload}),
        fetchActivityStream: context =>
            http('/api/dashboard/activityStream')
                .then(response => response.data)
                .then(response => {
                    context.commit('SET_ACTIVITY_STREAM', response);
                })
                .catch(() => {
                    throw new Error('Network exception');
                }),
        fetchConsultantTasks: (context, payload) => {
            if (!context.state.consultantTasks.length || (payload && payload.force)) {
                return http('/api/consultantTaskList')
                    .then(response => {
                        context.commit('SET_CONSULTANT_TASKS', response.data);
                    })
                    .catch(() => {
                        throw new Error('Network exception');
                    });
            }
        },
        addConsultantTask: (context, payload) => context.commit('ADD_CONSULTANT_TASK', payload),
        updateConsultantTaskState: (context, payload) => context.commit('CHANGE_CONSULTANT_TASK_STATE', payload),
        fetchContactTasks: (context, payload) => {
            if (!context.state.contactTasks.length || (payload && payload.force)) {
                return http('/api/taskList')
                    .then(response => {
                        context.commit('SET_CONTACT_TASKS', response.data);
                    })
                    .catch(() => {
                        throw new Error('Network exception');
                    });
            }
        },
        addContactTask: (context, payload) => context.commit('ADD_CONTACT_TASK', payload),
        createContactTask: (context, payload) =>
            http
                .post('/api/tasks/todo', JSON.stringify(payload))
                .then(serverResponse => serverResponse.data)
                .then(response => {
                    context.commit('ADD_CONTACT_TASK', response);
                })
                .catch(() => {
                    throw new Error('Network exception');
                }),
        updateContactTaskState: (context, payload) => context.commit('CHANGE_CONTACT_TASK_STATE', payload),
        changeContactTaskState: (context, payload) =>
            http
                .post(
                    '/api/tasks/updateTodo',
                    JSON.stringify({ id: payload.id, status: payload.status })
                )
                .then(serverResponse => serverResponse.data)
                .then(response => {
                    context.commit('CHANGE_CONTACT_TASK_STATE', response);
                })
                .catch(() => {
                    throw new Error('Network exception');
                }),
    },
};
