export default {
    namespaced: true,
    state: {
        tom: null,
    },
    mutations: {
        SET_ITEMS(state, tom) {
            state.tom = tom;
        },
        UNSHIFT_ITEMS: (state, payload) => {
            if (state.tom && state.tom[payload.field]) {
                if (payload.subField) {
                    state.tom[payload.field][payload.subField].unshift(
                        payload.data
                    );
                } else {
                    state.tom[payload.field].unshift(payload.data);
                }
            }
        },
    },
    actions: {
        unshiftItems: (context, payload) =>
            new Promise(resolve => {
                context.commit('UNSHIFT_ITEMS', payload);
                resolve();
            }),
        fetchItems(context, payload = {}) {
            payload.url = '/api/tom/tom';
            
            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_ITEMS', response.data);
                }
                return response;
            });
        },
    },
    getters: {
        getTomItems: state => state.tom,
        getDocumentationById: state => id =>
            state.tom.documentationItems.find(x => x.id === parseInt(id)),
        getPseudonymisationMeasures: state => {
            if (state.tom && state.tom.pseudonymisationMeasures) {
                return state.tom.pseudonymisationMeasures;
            }
        },
        getPseudonymisationMeasuresUuidItems: state => {
            if (state.tom && state.tom.pseudonymisationMeasures) {
                return state.tom.pseudonymisationMeasures.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getEncryptionMeasures: state => {
            if (state.tom && state.tom.encryptionMeasures) {
                return state.tom.encryptionMeasures;
            }
        },
        getEncryptionMeasuresUuidItems: state => {
            if (state.tom && state.tom.encryptionMeasures) {
                return state.tom.encryptionMeasures.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getConfidentialityMeasuresAccessControl: state => {
            if (state.tom && state.tom.confidentialityMeasures) {
                return state.tom.confidentialityMeasures.accessControl;
            }
        },
        getConfidentialityMeasuresAccessControlUuidItems: state => {
            if (state.tom && state.tom.confidentialityMeasures) {
                return state.tom.confidentialityMeasures.accessControl.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getConfidentialityMeasuresSystemControl: state => {
            if (state.tom && state.tom.confidentialityMeasures) {
                return state.tom.confidentialityMeasures.systemControl;
            }
        },
        getConfidentialityMeasuresSystemControlUuidItems: state => {
            if (state.tom && state.tom.confidentialityMeasures) {
                return state.tom.confidentialityMeasures.systemControl.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getConfidentialityMeasuresVolumeControl: state => {
            if (state.tom && state.tom.confidentialityMeasures) {
                return state.tom.confidentialityMeasures.volumeControl;
            }
        },
        getConfidentialityMeasuresVolumeControlUuidItems: state => {
            if (state.tom && state.tom.confidentialityMeasures) {
                return state.tom.confidentialityMeasures.volumeControl.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getConfidentialityMeasuresSeparationControl: state => {
            if (state.tom && state.tom.confidentialityMeasures) {
                return state.tom.confidentialityMeasures.separationControl;
            }
        },
        getConfidentialityMeasuresSeparationControlUuidItems: state => {
            if (state.tom && state.tom.confidentialityMeasures) {
                return state.tom.confidentialityMeasures.separationControl.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getIntegrityMeasuresDataIntegrityGuarantee: state => {
            if (state.tom && state.tom.integrityMeasures) {
                return state.tom.integrityMeasures.dataIntegrityGuarantee;
            }
        },
        getIntegrityMeasuresDataIntegrityGuaranteeUuidItems: state => {
            if (state.tom && state.tom.integrityMeasures) {
                return state.tom.integrityMeasures.dataIntegrityGuarantee.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getIntegrityMeasuresTransmissionControl: state => {
            if (state.tom && state.tom.integrityMeasures) {
                return state.tom.integrityMeasures.transmissionControl;
            }
        },
        getIntegrityMeasuresTransmissionControlUuidItems: state => {
            if (state.tom && state.tom.integrityMeasures) {
                return state.tom.integrityMeasures.transmissionControl.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getIntegrityMeasuresTransportControl: state => {
            if (state.tom && state.tom.integrityMeasures) {
                return state.tom.integrityMeasures.transportControl;
            }
        },
        getIntegrityMeasuresTransportControlUuidItems: state => {
            if (state.tom && state.tom.integrityMeasures) {
                return state.tom.integrityMeasures.transportControl.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getIntegrityMeasuresInputControl: state => {
            if (state.tom && state.tom.integrityMeasures) {
                return state.tom.integrityMeasures.inputControl;
            }
        },
        getIntegrityMeasuresInputControlUuidItems: state => {
            if (state.tom && state.tom.integrityMeasures) {
                return state.tom.integrityMeasures.inputControl.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getAvailabilityAndResilienceMeasuresAvailabilityControl: state => {
            if (state.tom && state.tom.availabilityAndResilienceMeasures) {
                return state.tom.availabilityAndResilienceMeasures
                    .availabilityControl;
            }
        },
        getAvailabilityAndResilienceMeasuresAvailabilityControlUuidItems: state => {
            if (state.tom && state.tom.availabilityAndResilienceMeasures) {
                return state.tom.availabilityAndResilienceMeasures.availabilityControl.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getAvailabilityAndResilienceMeasuresRecoverability: state => {
            if (state.tom && state.tom.availabilityAndResilienceMeasures) {
                return state.tom.availabilityAndResilienceMeasures
                    .recoverability;
            }
        },
        getAvailabilityAndResilienceMeasuresRecoverabilityUuidItems: state => {
            if (state.tom && state.tom.availabilityAndResilienceMeasures) {
                return state.tom.availabilityAndResilienceMeasures.recoverability.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getAvailabilityAndResilienceMeasuresReliability: state => {
            if (state.tom && state.tom.availabilityAndResilienceMeasures) {
                return state.tom.availabilityAndResilienceMeasures.reliability;
            }
        },
        getAvailabilityAndResilienceMeasuresReliabilityUuidItems: state => {
            if (state.tom && state.tom.availabilityAndResilienceMeasures) {
                return state.tom.availabilityAndResilienceMeasures.reliability.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getEvaluationOfDataProcessingSecurityMeasuresReviewProcess: state => {
            if (
                state.tom &&
                state.tom.evaluationOfDataProcessingSecurityMeasures
            ) {
                return state.tom.evaluationOfDataProcessingSecurityMeasures
                    .reviewProcess;
            }
        },
        getEvaluationOfDataProcessingSecurityMeasuresReviewProcessUuidItems: state => {
            if (
                state.tom &&
                state.tom.evaluationOfDataProcessingSecurityMeasures
            ) {
                return state.tom.evaluationOfDataProcessingSecurityMeasures.reviewProcess.filter(
                    x => x.clientId && !x.id
                );
            }
        },
        getEvaluationOfDataProcessingSecurityMeasuresOrderControl: state => {
            if (
                state.tom &&
                state.tom.evaluationOfDataProcessingSecurityMeasures
            ) {
                return state.tom.evaluationOfDataProcessingSecurityMeasures
                    .orderControl;
            }
        },
        getEvaluationOfDataProcessingSecurityMeasuresOrderControlUuidItems: state => {
            if (
                state.tom &&
                state.tom.evaluationOfDataProcessingSecurityMeasures
            ) {
                return state.tom.evaluationOfDataProcessingSecurityMeasures.orderControl.filter(
                    x => x.clientId && !x.id
                );
            }
        },
    },
};
