import Vue from 'vue';
import { groupBy } from 'lodash';

export default {
    namespaced: true,
    state: {
        items: [],
    },
    mutations: {
        SET_ITEMS(state, items) {
            // items is an object with the companyId as key which holds an array of departments
            const groupedByCompanyId = groupBy(items, 'companyId');
            state.items = { ...state.items, ...groupedByCompanyId };
        },
        EDIT_COMPANY_DEPARTMENT(state, payload) {
            const itemIndex = state.items[payload.companyId].findIndex(
                x => x.id === payload.id
            );
            Vue.set(state.items[payload.companyId], itemIndex, {
                ...state.items[payload.companyId][itemIndex],
                ...{
                    title: payload.title,
                    departmentCategories: payload.departmentCategories,
                },
            });
        },
        REMOVE_COMPANY_DEPARTMENT(state, payload) {
            if (state.items[payload.companyId]) {
                const itemIndex = state.items[payload.companyId].findIndex(
                    x => x.id === payload.id
                );
                Vue.delete(state.items[payload.companyId], itemIndex);
            }
        },
        ADD_COMPANY_DEPARTMENT(state, payload) {
            if (!state.items[payload.companyId]) {
                state.items[payload.companyId] = [];
            }
            state.items[payload.companyId].push(payload);
        },
        SET_COMPANY_DEPARTMENTS(state, payload) {
            if (!state.items[payload.companyId]) {
                state.items[payload.companyId] = [];
            }
            state.items[payload.companyId] = payload.departments;
        },
    },
    actions: {
        fetchByCompanyId: (context, payload = {}) => {
            payload.url = '/api/departmentsByCompany/' + payload.id;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_COMPANY_DEPARTMENTS', response.data);
                }
                return response;
            });
        },
        fetchById: (context, payload = {}) => {
            payload.url = '/api/departmentById/' + payload.id;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                return response;
            }, (reason) => {
                throw new Error(reason);
            });
        },
        edit(context, payload = {}) {
            payload.url = '/api/admin/editCompanyDepartment';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('EDIT_COMPANY_DEPARTMENT', payload);
                }
                return response;
            });
        },
        add(context, payload = {}) {
            payload.url = '/api/admin/addCompanyDepartment';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('ADD_COMPANY_DEPARTMENT', payload);
                }
                return response;
            });
        },
        delete(context, payload = {}) {
            payload.url = '/api/admin/deleteCompanyDepartment';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('REMOVE_COMPANY_DEPARTMENT', payload);
                }
                return response;
            });
        },
    },
    getters: {
        getItems: state => state.items,
        getItemsByCompany: state => companyId => state.items[companyId]
    },
};
