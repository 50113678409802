import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        items: [],
        itemsFetched: false
    },
    getters: {
        getItems: state => state.items,
        getUuidItems: state => state.items.filter(x => x && x.clientId && !x.id),
    },
    mutations: {
        SET_ITEMS(state, items) {
            state.items = items;
        },
        SET_FETCHED(state, reset = false) {
            state.itemsFetched = !reset;
        },
        ADD_ITEM: (state, payload) => {
            const newItem = payload.data;
            const itemIndex = newItem.id ? state.items.findIndex(x => x && x.id === newItem.id) : state.items.findIndex(x => x && x.clientId === newItem.clientId);
            if(itemIndex === -1) {
                state.items.unshift(newItem);
            }
        },
        EDIT_ITEM(state, payload) {
            const itemIndex = state.items.findIndex(x => x.id === payload.id);
            Vue.set(state.items, itemIndex, {
                ...state.items[itemIndex],
                ...payload,
            });
        },
        REMOVE_ITEM(state, payload) {
            const itemIndex = state.items.findIndex(x => x.id === payload.id);
            Vue.delete(state.items, itemIndex);
        },
    },
    actions: {
        setItems: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_ITEMS', payload);
                resolve();
            }),
        unshiftItems: (context, payload) =>
            new Promise(resolve => {
                context.commit('ADD_ITEM', payload.data ? payload : {data: payload});
                resolve();
            }),
        addItem: (context, payload) =>
            new Promise(resolve => {
                context.commit('ADD_ITEM', payload.data ? payload : {data: payload});
                resolve();
            }),
        removeItem: (context, payload) =>
            new Promise(resolve => {
                context.commit('REMOVE_ITEM', payload);
                resolve();
            }),
        changeItem: (context, payload) =>
            new Promise(resolve => {
                context.commit('EDIT_ITEM', payload);
                resolve();
            }),
        fetch(context, payload = {}) {
            payload.url = '/api/externalRecipientCategories';
            const disableStoreUpdate = payload.disableStoreUpdate || false;
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage && context.state.itemsFetched) {
                return {data: context.getters.getItems};
            }
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!disableStoreUpdate && !response.data.error) {
                    context.commit('SET_FETCHED');
                    context.commit('SET_ITEMS', response.data);
                }
                return response;
            });
        },
        fetchById: (context, payload) => {
            payload.url = '/api/admin/externalRecipientCategoryById/' + payload.id;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                return response;
            });
        },
        create: (context, payload = {}) => {
            payload.url = '/api/admin/externalRecipientCategories/create';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('ADD_ITEM', {
                        data: response.data,
                    });
                }
                return response;
            });
        },
        edit: (context, payload = {}) => {
            payload.url = '/api/admin/externalRecipientCategories/edit';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('EDIT_ITEM', response.data);
                }
                return response;
            });
        },
        delete: (context, payload = {}) => {
            payload.url = '/api/admin/externalRecipientCategories/delete';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('REMOVE_ITEM', payload);
                }
                return response;
            });
        },
        findUsage: (context, payload = {}) => {
            payload.url = '/api/admin/externalRecipientCategories/findUsage';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
        replaceItemInRecord: (context, payload = {}) => {
            payload.url = '/api/admin/externalRecipientCategories/replace';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
        clearItems({ commit }) {
            commit('SET_FETCHED', true);
            commit('SET_ITEMS', []);
        },
    },
};
