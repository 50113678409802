//import '@babel/polyfill';
import Lea from './plugins/lea.js'
import cssVars from 'css-vars-ponyfill';
import PortalVue from 'portal-vue'
import Vue from 'vue';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import VeeValidate from 'vee-validate';
import vuetify from './plugins/vuetify';
import './plugins/scrollto';
import wait from './plugins/wait';
import i18n from './plugins/i18n';
import moveItem from './plugins/moveItem';
import clearInputOnChange from './directives/clearInputOnChange';
import clearInputOnBlur from './directives/clearInputOnBlur';
import App from './App.vue';
import { store } from './store/store';
import router from './router';
import { getDetails } from './utils/auth';
require('./assets/fonts/font.css'); 
require('./assets/fonts/materialdesignicons.css'); 
require('vue2-dropzone/dist/vue2Dropzone.min.css'); 
require('./assets/scss/layout.scss');
require('./assets/scss/generic.scss');
require('./assets/scss/v-button.scss');
require('./assets/scss/v-card.scss');
require('./assets/scss/input.scss');

cssVars({
    // Options...
});
Vue.use(PortalVue);

const requireComponent = require.context(
    // The relative path of the components folder
    './components',
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
            // Strip the leading `./` and extension from the filename
            fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
        )
    );

    // Register component globally
    Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
    );
});
if (getDetails() !== null) {
    i18n.locale = getDetails().preferredLanguage.toLowerCase();
}

Vue.use(VeeValidate, {
    inject: false, // see https://github.com/baianat/vee-validate/issues/1547
    i18n,
    i18nRootKey: 'validations',
    events: 'change|blur',
    // events: 'passive'
});
Vue.use(Lea);
Vue.use(moveItem);
Vue.directive('clear-input-on-change', clearInputOnChange);
Vue.directive('clear-input-on-blur', clearInputOnBlur);

Vue.config.productionTip = false;
export const app = new Vue({
    vuetify,
    store,
    i18n,
    router,
    wait,
    render: h => h(App),
}).$mount('#app');
