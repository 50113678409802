export default {
    namespaced: true,
    state: {
        items: [],
        itemsFetched: false
    },
    getters: {
        getItems: state => state.items,
    },
    mutations: {
        SET_FETCHED(state, reset = false) {
            state.itemsFetched = !reset;
        },
        SET_ITEMS(state, items) {
            state.items = items;
        },
    },
    actions: {
        fetch(context, payload = {}) {
            payload.url = '/api/legality';
            const disableStoreUpdate = payload.disableStoreUpdate || false;
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage && context.state.itemsFetched) {
                return {data: context.getters.getItems};
            }
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!disableStoreUpdate && !response.data.error) {
                    context.commit('SET_FETCHED');
                    context.commit('SET_ITEMS', response.data);
                }
                return response;
            });
        },
        clearItems({ commit }) {
            commit('SET_FETCHED', true);
            commit('SET_ITEMS', []);
        },
    },
};
