import { v4 as uuid } from 'uuid';
import { uniq } from 'lodash';

export function isUUID(val) {
    if (!val || val.length <= 0) {
        return false;
    }
    var re = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return val.toString().match(re);
}

export default {
    methods: {
        /**
         * @param {Array} items 
         * @param {Array, Number, String, Object} value 
         * @returns {Array, Object, Null}
         */
        getValue(items, value) {
            if (value === null || typeof value === 'undefined') {
                return null;
            }
            /**
             * Transform flat array with ids (from server) to an array 
             * of item objects by using given items
             */
            if (Array.isArray(value) && value.length > 0) {
                return value.map(x => {
                    if (
                        x &&
                        typeof x === 'object' &&
                        !x.id &&
                        isUUID(x.clientId)
                    ) {
                        return x;
                    }
                    return items.find(i => i && (i.id === x || i.clientId === x));
                });
            /**
             * Transform number/id value to an object with title and other data
             * by using given items
             */
            } else if (typeof value === 'number') {
                return items.find(i => i.id === value || (i.clientId && i.clientId === value));
            /**
             * Transform object item (maybe without title) to an object with title and other data
             * by using given items
             */
            } else if (value.id || value.clientId) {
                return items.find(i => i.id === value.id || (i.clientId && i.clientId === value.clientId));
            }
            return value;
        },
        setValue(
            items,
            val,
            fieldName = null, //tom still use 'name'
            obj = null,
            itemsDispatcher = null,
            dispatchObj = null
        ) {
            if(!fieldName) {
                fieldName = 'title';
            }
            if (val === null || typeof val === 'undefined') {
                return null;
            }
            if (Array.isArray(val) && val.length > 0) {
                return this.setMultipleValue(items, val, fieldName, obj, itemsDispatcher, dispatchObj);
            } else if (typeof val === 'string') {
                val = val.replace(/^\s+|\s+$/g, '').replace(/\s\s/g, ' ');
                const existingItem = items.find(i => i[fieldName] === val);
                if (existingItem) {
                    return existingItem;
                }

                const objReturn = {};
                objReturn.clientId = uuid();
                objReturn[fieldName] = val;
                // additional fields
                if (obj !== null) {
                    for (var key in obj) {
                        if (Object.prototype.hasOwnProperty.call(obj, key)) {
                            objReturn[key] = obj[key];
                        }
                    }
                }
                if (itemsDispatcher) {
                    this.storeItemInStore(itemsDispatcher, objReturn, dispatchObj);
                }
                return objReturn;
            }
            return val;
        },
        setMultipleValue(items, val, fieldName, obj = null, itemsDispatcher = null, dispatchObj = null) {
            return uniq(val.map(x => {
                if (isUUID(x)) {
                    return x;
                } else if (typeof x === 'string') {
                    x = x.replace(/^\s+|\s+$/g, '').replace(/\s\s/g, ' ');
                    const existingItem = items.find(
                        i => i[fieldName] === x
                    );
                    if (existingItem) {
                        return existingItem.id || existingItem.clientId;
                    }
                    
                    val = {};
                    val.clientId = uuid();
                    val[fieldName] = x;
                    // additional fields
                    if (obj !== null) {
                        for (var key in obj) {
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    obj,
                                    key
                                )
                            ) {
                                val[key] = obj[key];
                            }
                        }
                    }
                    if (itemsDispatcher) {
                        this.storeItemInStore(itemsDispatcher, val, dispatchObj);
                    }
                    return val.id || val.clientId;
                }
                return typeof x === 'object' ? x.id || x.clientId : x;
            }));
        },
        storeItemInStore(itemsDispatcher, val, dispatchObj = null) {
            this.$store.dispatch(itemsDispatcher, {
                ...dispatchObj,
                ...{ data: val },
            });
        },
        getIndexedValueFromIdValue(items, value) {
            if (value === null) {
                return -1;
            }
            if (Array.isArray(value)) {
                const data = [];
                value.forEach(x => {
                    const itemIndex = items.findIndex(
                        i => i.id === x || i.clientId === x
                    );
                    if (itemIndex >= 0) {
                        data.push(itemIndex);
                    }
                });
                return data;
            } else if (Number.isInteger(value)) {
                const itemIndex = items.findIndex(
                    i => i.id === value || i.clientId === value
                );
                if (itemIndex >= 0) {
                    return itemIndex;
                }
            }
        },
        setIndexedValueFromIdValue(items, value) {
            if (Array.isArray(value)) {
                const data = [];
                value.forEach(x => {
                    const item = items[x];
                    if (item) {
                        data.push(item.id || item.clientId);
                    }
                });
                return data;
            } else if (Number.isInteger(value)) {
                if (items[value] && items[value].id) {
                    return items[value].id;
                } else if (items[value] && items[value].clientId) {
                    return items[value].clientId;
                }
            }
        },
    },
};
