<template>
    <div id="app">
        <vue-extend-layouts />
    </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout';
import http from '@/utils/axios';
import { getToken, getDetails } from '@/utils/auth';

export default {
    name: 'App',
    components: { VueExtendLayouts },
    created() {
        if (process.env.VUE_APP_SSO !== 'true' && getToken() && process.env.VUE_APP_DISABLE_INTERCEPTORS !== 'true') {
            http.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
        }
        if (getDetails() && getDetails().preferredLanguage) {
            http.defaults.headers.common[
                'Accept-Language'
            ] = `${getDetails().preferredLanguage.toLowerCase()}`;
        }
    },
};
</script>
