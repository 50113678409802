export default {
    namespaced: true,
    state: {
        data: {
            selectedCompanyId: null,
            disableApiVersionConflictDialog: false
        },
    },
    mutations: {
        CLEAR_ITEMS(state) {
            state.data.selectedCompanyId = null;
            state.data.disableApiVersionConflictDialog = false;
        },
        ADD_ITEM(state, item) {
            if (state.data[item.name]) {
                state.data[item.name].push(item.value);
            }
        },
        REMOVE_ITEM(state, item) {
            if (state.data[item.name]) {
                state.data[item.name].splice(
                    state.data[item.name].indexOf(item.value),
                    1
                );
            }
        },
        SET_ITEMS(state, item) {
            if (Object.prototype.hasOwnProperty.call(state.data, item.name)) {
                state.data[item.name] = item.value;
            }
        },
        DISABLE_API_VERSION_CONFLICT_DIALOG(state) {
            state.data.disableApiVersionConflictDialog = true;
        },
    },
    actions: {
        add({ commit }, payload) {
            return new Promise(resolve => {
                resolve(payload);
            }).then(response => {
                commit('ADD_ITEM', response);
            });
        },
        remove({ commit }, payload) {
            return new Promise(resolve => {
                resolve(payload);
            }).then(response => {
                commit('REMOVE_ITEM', response);
            });
        },
        set({ commit }, payload) {
            return new Promise(resolve => {
                resolve(payload);
            }).then(response => {
                commit('SET_ITEMS', response);
            });
        },
        disableApiVersionConflictDialog({ commit }) {
            return new Promise(resolve => {
                resolve();
            }).then(() => {
                commit('DISABLE_API_VERSION_CONFLICT_DIALOG');
            });
        },
        clearAll({ commit }) {
            commit('CLEAR_ITEMS');
        },
    },
    getters: {
        getData: state => state.data,
        getDataByKey: state => key => state.data[key]
    },
};
