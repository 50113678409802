

import http from '@/utils/axios';
import { getToken, getTokenExpirationDate, isAuthenticated, hasRole, getDetails } from '@/utils/auth';
import FileSaver from 'file-saver';
import { isMatch, dateTypes, parseISO, formatDate } from '@/utils/dateFns';
import Vuetify from '@/plugins/vuetify';

const Lea = {
    install(Vue) {
        Vue.use(Vuetify);

        Vue.devMode = Vue.prototype.$devMode = (process.env.NODE_ENV === 'development');
        Vue.ssoMode = Vue.prototype.$ssoMode = (process.env.VUE_APP_SSO === 'true');
        Vue.leaVersion = Vue.prototype.$leaVersion = process.env.VUE_APP_VERSION;
        Vue.publicPath = Vue.prototype.$publicPath = './';
        Vue.prototype.$dynamicTextColorPrimary = function(darkMode = false) {
            return {
               'primary--text': !darkMode,
               'white--text': darkMode,
            };
        }
        Vue.prototype.$isDark = function() {
            return Vuetify.framework.theme.dark;
        }
        Vue.prototype.$leaContentHeight = function(sub) {
            let substract = parseInt(Vuetify.framework.application.top) + this.$footerHeight() + sub;
            return Vuetify.framework.breakpoint.mdAndDown ? `calc(50vh - ${substract}px)` : `calc(100vh - ${substract}px)`;
        }
        Vue.prototype.$footerHeight = function() {
            if (parseInt(Vuetify.framework.application.footer) > 0) {
                return Vuetify.framework.application.footer;
            }
            return Vuetify.framework.breakpoint.xsOnly ? 65 : 33;
        }
        Vue.prototype.$getMyDetailProp = function(val) {
            return this.$objectPropertyExists(getDetails(), val) ? getDetails()[val] : null;
        }
        Vue.prototype.$myContactId = function() {
            return getDetails() && getDetails().contactId;
        }
        Vue.prototype.$getTokenTimeLeft = function() {
            if(!getToken() || !getTokenExpirationDate(getToken())) {
                return 0;
            }
            let currentDate = new Date();
            let timeLeft = getTokenExpirationDate(getToken()).getTime() - currentDate.getTime();
            return timeLeft;
        }
        Vue.prototype.$hasAuth = function() {
            return isAuthenticated();
        }
        Vue.prototype.$hasRole = function(role) {
            return hasRole(role);
        }
        Vue.prototype.$getDropdownTypeFor = function(fieldName, enabledUserFreetextInputFields) {
            if(hasRole('ROLE_TENANT_EDITOR') || (Array.isArray(enabledUserFreetextInputFields) && enabledUserFreetextInputFields.includes(fieldName))) {
                return 'combobox';
            }
            return 'autocomplete';
        }
        Vue.prototype.$log = function(message) {
            console.log('lea.js $logg: ', message);
        }
        Vue.prototype.$isObject = function(el) {
            return typeof el === 'object' && el !== null;
        }
        Vue.prototype.$objectPropertyExists = function(obj, prop) {
            return this.$isObject(obj) && Object.prototype.hasOwnProperty.call(obj, prop);
        }
        Vue.prototype.$nl2br = function(str, isXhtml = true) {
            if (typeof str === 'undefined' || str === null) {
              return ''
            }
            var breakTag = isXhtml ? '<br ' + '/>' : '<br>'
            return (str + '').replace(/(\r\n|\n\r|\r|\n)/g, breakTag + '$1')
        }
        Vue.prototype.$humaniseFileSize = function(bytes) {
            const unitName = ['B', 'kB', 'MB'];
            if (isNaN(parseFloat(bytes))) {
                return '-';
            }
            if (bytes < 1) {
                return '0 B';
            }
            const unit = Math.floor(Math.log(bytes) / Math.log(1024));
            const size = bytes / Math.pow(1024, unit);
          
            return `${size.toFixed(1)} ${unitName[unit]}`;
        }
        Vue.prototype.$htmlSnippet = function(value, arg1) {
            const text = this.$htmlText(value, false);
            const textSliced = text.slice(0, parseInt(arg1));
            const strAppend = text.length > textSliced.length ? '...' : '';
            return text.slice(0, parseInt(arg1)) + strAppend;
        }
        Vue.prototype.$htmlText = function(value, breakOnNewline = true) {
            const div = document.createElement('div');
            div.innerHTML = value;
            const str = div.textContent || div.innerText || '';
            return breakOnNewline ? this.$nl2br(str) : str;
        }
        Vue.prototype.$hasClass = function(el, className) {
            if (el.classList) {
                return el.classList.contains(className);
            }
            return false;
        }
        Vue.prototype.$rightSheetWidth = function() {
            return Vuetify.framework.breakpoint.xs
                    ? 'calc(100% - 56px)'
                    : Vuetify.framework.breakpoint.sm
                    ? 'calc(100% - 56px)'
                    : Vuetify.framework.breakpoint.md
                    ? 'calc(100% - 56px)'
                    : Vuetify.framework.breakpoint.lg
                    ? '60%'
                    : '45%';
        }
        Vue.prototype.$windowScrollState = Vue.observable({
            value: Vue.prototype.$hasClass(document.body, 'window--scrolled'),
            getValue() {
                return this.value
            },
            setValue(scrollY) {
                if (scrollY > 0) {
                    Vue.prototype.$addClass(document.body, 'window--scrolled');
                } else {
                    Vue.prototype.$removeClass(document.body, 'window--scrolled');
                }
            }
        })

        Vue.prototype.$translationState = Vue.observable({
            value: Vue.prototype.$objectPropertyExists(localStorage, 'LEA_TRANSLATION_STATE') ? JSON.parse(localStorage.getItem('LEA_TRANSLATION_STATE')) === true : false,
            getValue() {
                return this.value
            },
            setValue(val) {
                this.value = val
                localStorage.setItem('LEA_TRANSLATION_STATE', this.value);
            }
        })
        Vue.prototype.$getStatusColorClass = function(status, mode = null) {
            status = status.toLowerCase();
            if (status === 'finalised') {
                return mode === 'rgb' ? 'rgb(76, 175, 80)' : mode === 'hex' ? '#4caf50' : 'green white--text';
            }
            if (status === 'draft') {
                return mode === 'rgb' ? 'rgb(238, 238, 238)' : mode === 'hex' ? '#eeeeee' : 'grey lighten-3';
            }
            if (status === 'archived') {
                return mode === 'rgb' ? 'rgb(158, 158, 158)' : mode === 'hex' ? '#9e9e9e' : 'grey';
            }
            if (status === 'review') {
                return mode === 'rgb' ? 'rgb(253, 216, 1)' : mode === 'hex' ? '#fdd801' : 'yellow darken-1';
            }
            if (status === 'approval') {
                return mode === 'rgb' ? 'rgb(255, 152, 0)' : mode === 'hex' ? '#ff9800' : 'orange';
            }
            if (status === 'release') {
                return mode === 'rgb' ? 'rgb(230, 81, 0)' : mode === 'hex' ? '#e65100' : 'orange darken-4 white--text';
            }

            if (status === 'inwork') {
                return mode === 'rgb' ? 'rgb(225, 225, 225)' : mode === 'hex' ? '#e1e1e1' : 'grey lighten-2';
            }
            return;
        }
        Vue.prototype.$getPriorityColorClass = function(intPrio) {
            intPrio = parseInt(intPrio);
            return intPrio === 1 ? 'red accent-4 white--text' : intPrio === 2 ? 'orange accent-4 white--text' : 'green white--text';
        }
        Vue.prototype.$isString = function(x) {
            return Object.prototype.toString.call(x) === '[object String]';
        }
        Vue.prototype.$sortAlphabetically = function(items, fieldName = null) {
            if (!items || !Array.isArray(items)) {
                return [];
            }
            return items.sort((valA, valB) => {
                let a = fieldName ? valA[fieldName] : valA;
                let b = fieldName ? valB[fieldName] : valB;
                a = this.$isString(a) ? a.toLowerCase() : a;
                b = this.$isString(b) ? b.toLowerCase() : b;
                if(a < b) { return -1; }
                if(a > b) { return 1; }
                return 0;
            });
        }
        Vue.prototype.$sortNumerical = function(items, fieldName = null) {
            if (!Array.isArray(items)) {
                return [];
            }
            return items.sort((valA, valB) => {
                const a = fieldName ? valA[fieldName] : valA;
                const b = fieldName ? valB[fieldName] : valB;
                return a - b;
            });
        }
        Vue.prototype.$outerHeight = function(el) {
            let height = el.offsetHeight;
            const style = getComputedStyle(el);
            height += parseInt(style.marginTop) + parseInt(style.marginBottom);

            return height;
        }
        Vue.prototype.$outerWidth = function(el) {
            let width = el.offsetWidth;
            const style = getComputedStyle(el);
            width += parseInt(style.marginLeft) + parseInt(style.marginRight);

            return width;
        }
        Vue.prototype.$windowHeight = function() {
            return this.$windowSize().y;
        }
        Vue.prototype.$windowSize = function() {
            const w = window;
            const d = document;
            const e = d.documentElement;
            const g = d.getElementsByTagName('body')[0];
            const x = w.innerWidth || e.clientWidth || g.clientWidth;
            const y = w.innerHeight || e.clientHeight || g.clientHeight;
            return { x: x, y: y };
        }
        Vue.prototype.$addClass = function(el, className) {
            if (!el.classList || !el.classList.contains(className)) {
                el.classList.add(className);
            }
        }
        Vue.prototype.$removeClass = function(el, className) {
            if (el.classList && el.classList.contains(className)) {
                el.classList.remove(className);
            }
        }
        Vue.prototype.$setStorage = function(key, val) {
            if (val !== undefined) {
                localStorage.setItem(key, val);
            } else {
                localStorage.removeItem(key);
            }
        }
        Vue.prototype.$generateDownload = function(downloadConfig) {
        
            if (!downloadConfig) {
                return;
            }

            this.$wait.start('pageLoader');


            let fileExt = '.pdf';
            if (downloadConfig.contentType === 'word') {
                fileExt = '.docx';
            }
            if (downloadConfig.contentType === 'excel') {
                fileExt = '.xlsx';
            }

            const request = {
                language: downloadConfig.language,
                contentType: downloadConfig.contentType,
                companyId: downloadConfig.companyId,
                exportType: downloadConfig.exportType,
                exportHistoryDate: downloadConfig.exportHistoryDate,
                filterId:
                    downloadConfig.filterId === 'all'
                        ? null
                        : downloadConfig.filterId,
                recordId: downloadConfig.recordId
            };
            http.post('/api/export', request)
                .then(response => response.data)
                .then(response => {
                    let fileId;
                    if (downloadConfig.contentType === 'word') {
                        fileId = response.docx;
                    } else if (downloadConfig.contentType === 'excel') {
                        fileId = response.excel;
                    } else {
                        fileId = response.pdf;
                    }
                    this.$wait.end('pageLoader');
                    let fileName;

                    let fileDate = new Date();

                    if(downloadConfig.exportHistoryDate) {
                        if(isMatch(downloadConfig.exportHistoryDate, 'yyyy-MM-dd')) {
                            fileDate = new Date(downloadConfig.exportHistoryDate + 'T00:00:00');
                        } else if(isMatch(downloadConfig.exportHistoryDate, 'yyyy-MM')) {
                            fileDate = new Date(downloadConfig.exportHistoryDate + '-01T00:00:00');
                        }
                    }
                    const date = formatDate(
                        parseISO(fileDate.toISOString()),
                        dateTypes.fileDate
                    );
                    if(downloadConfig.processingActivityId) {
                        fileName = `LEA_${downloadConfig.companyName}_record-${downloadConfig.processingActivityId}_${downloadConfig.exportType}_${downloadConfig.language}_${date}`;
                    } else {
                        fileName = `LEA_${downloadConfig.companyName}_records_${downloadConfig.exportType}_${downloadConfig.language}_${date}`;
                    }
                    fileName = fileName.replace(/[/\\?%*:|"<> ]/g, '-');

                    if(fileId) {
                        this.$getFile(`/api/download/${fileId}`, encodeURIComponent(fileName) + fileExt);
                    }
                    
                })
                .catch(() => {
                    this.$wait.end('pageLoader');
                    throw new Error('Network exception');
                });
        }
        Vue.prototype.$getFile = function(link, filename, disablePageLoader = false) {

            if (!link || !filename) {
                return;
            }
            this.$wait.start('fetching file');
            if(!disablePageLoader) {
                this.$wait.start('pageLoader');
            }

            http.get(link, { responseType: 'blob' })
            .then(response => {
                this.$wait.end('fetching file');
                if(!disablePageLoader) {
                    this.$wait.end('pageLoader');
                }
                FileSaver.saveAs(response.data, filename);
            })
            .catch(() => {
                this.$wait.end('fetching file');
                if(!disablePageLoader) {
                    this.$wait.end('pageLoader');
                }
            });
        }
        Vue.prototype.$setIdByClientId = function(storedItems, newItems) {
            return storedItems.map(storedItem => {
                if (storedItem.clientId) {
                    newItems.forEach(newItem => {
                        if (storedItem.clientId === newItem.clientId) {
                            storedItem.id = newItem.id;
                            storedItem.clientId = null;
                        }
                    });
                }
                return storedItem;
            });
        }
    }
};

export default Lea;
