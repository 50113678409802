import Vue from 'vue';
import router from '@/router';

export default {
    namespaced: true,
    state: {
        items: [],
        itemsFetched: false,
        filterResultDomains: {},
    },
    getters: {
        getItems: state => state.items,
        getFilteredDomains: state => filterId =>
            state.filterResultDomains && state.filterResultDomains[filterId]
                ? state.filterResultDomains[filterId]
                : [],
    },
    mutations: {
        SET_ITEMS(state, items) {
            state.items = items;
        },
        SET_FETCHED(state, reset = false) {
            state.itemsFetched = !reset;
        },
        ADD_ITEM: (state, payload) => {
            const newItem = payload.data;
            const itemIndex = newItem.id ? state.items.findIndex(x => x && x.id === newItem.id) : state.items.findIndex(x => x && x.clientId === newItem.clientId);
            if(itemIndex === -1) {

                const keys = Object.keys(state.filterResultDomains);
                for (const key of keys) {
                    const domainIndex = state.filterResultDomains[key].findIndex(x => x.id === newItem.id);
                    if(domainIndex <= -1) {
                        Vue.set(state.filterResultDomains, key, newItem);
                    }
                }
                state.items.unshift(newItem);

            }

        },
        EDIT_ITEM(state, payload) {

            const keys = Object.keys(state.filterResultDomains);
            for (const key of keys) {
                const domainIndex = state.filterResultDomains[key].findIndex(x => x.id === payload.data.id);
                if(domainIndex > -1) {
                    const newData = {
                        ...state.filterResultDomains[key][domainIndex],
                        ... {
                            alias: payload.data.alias,
                            title: payload.data.title
                        }
                    };
                    Vue.set(state.filterResultDomains[key], domainIndex, newData);
                }
            }

            const itemIndex = state.items.findIndex(x => x.id === payload.id);
            Vue.set(state.items, itemIndex, {
                ...state.items[itemIndex],
                ...payload,
            });
        },
        REMOVE_ITEM(state, payload) {
            
            const keys = Object.keys(state.filterResultDomains);
            for (const key of keys) {
                const domainIndex = state.filterResultDomains[key].findIndex(x => x.id === payload.data.id);
                if(domainIndex > -1) {
                    Vue.delete(state.filterResultDomains[key], domainIndex);
                }
            }

            const itemIndex = state.items.findIndex(x => x.id === payload.id);
            if (itemIndex > -1) {
                Vue.delete(state.items, itemIndex);
            }
        },
        SET_FILTER_RESULT_DOMAINS(state, payload) {
            Vue.set(
                state.filterResultDomains,
                payload.filterId,
                payload.response
            );
        },
    },
    actions: {
        setItems: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_ITEMS', payload);
                resolve();
            }),
        unshiftItems: (context, payload) =>
            new Promise(resolve => {
                context.commit('ADD_ITEM', payload.data ? payload : {data: payload});
                resolve();
            }),
        addItem: (context, payload) =>
            new Promise(resolve => {
                context.commit('ADD_ITEM', payload.data ? payload : {data: payload});
                resolve();
            }),
        removeItem: (context, payload) =>
            new Promise(resolve => {
                context.commit('REMOVE_ITEM', payload);
                resolve();
            }),
        changeItem: (context, payload) =>
            new Promise(resolve => {
                context.commit('EDIT_ITEM', payload);
                resolve();
            }),
        fetch(context, payload = {}) {
            payload.url = '/api/domains';
            const disableStoreUpdate = payload.disableStoreUpdate || false;
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage && context.state.itemsFetched) {
                return {data: context.getters.getItems};
            }
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!disableStoreUpdate && !response.data.error) {
                    context.commit('SET_FETCHED');
                    context.commit('SET_ITEMS', response.data);
                }
                return response;
            });
        },
        fetchByFilter(context, payload = {}) {
            let route = router && router.history && router.history.current ? router.history.current : null;
            if(route && route.query) {
                if(route.query.filterAlias) {
                    payload.filterAlias = route.query.filterAlias;
                }
                if(route.query.filterValue) {
                    payload.filterValue = route.query.filterValue;
                }
            }

            if (!Object.prototype.hasOwnProperty.call(payload, 'filterId')) {
                payload.filterId = 'all';
            }
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            const disableStoreUpdate = payload && payload.disableStoreUpdate
                                ? payload.disableStoreUpdate
                                : false;
            if (
                !force && !forcedLanguage &&
                context.state.filterResultDomains &&
                context.getters.getFilteredDomains(payload.filterId).length
            ) {
                return {
                    data: context.getters.getFilteredDomains(payload.filterId)
                }
            }

            payload.url = '/api/filter/domainsByFilter';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!disableStoreUpdate && !response.data.error) {
                    context.commit('SET_FILTER_RESULT_DOMAINS', {
                        filterId: payload.filterId,
                        response: response.data,
                    });
                }
                return response;
            });            
        },
        fetchById: (context, payload = {}) => {
            payload.url = '/api/domain/' + payload.id;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                return response;
            });
        },
        create: (context, payload = {}) => {
            payload.url = '/api/admin/domain/add';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('ADD_ITEM', {
                        data: response.data,
                    });
                }
                return response;
            });
        },
        edit: (context, payload = {}) => {
            payload.url = '/api/admin/domain/edit';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('EDIT_ITEM', response.data);
                }
                return response;
            });
        },
        delete: (context, payload = {}) => {
            payload.url = '/api/admin/domain/delete';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('REMOVE_ITEM', payload);
                }
                return response;
            });
        },
        findUsage: (context, payload = {}) => {
            payload.url = '/api/admin/domain/findUsage';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
        replaceItemInRecord: (context, payload) => {
            payload.url = '/api/admin/domain/replace';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
        clearItems({ commit }) {
            commit('SET_FETCHED', true);
            commit('SET_ITEMS', []);
        },
    }
};
