import { groupBy, chain } from 'lodash';
import { dateTypes, parseISO, formatRelativeGroup } from '@/utils/dateFns';

export default {
    namespaced: true,
    state: {
        items: [],
    },
    getters: {
        getGroupedItems: state => {
            const groupedActivity = groupBy(state.items, item =>
                formatRelativeGroup(
                    parseISO(item.date),
                    new Date(),
                    'detail',
                    dateTypes.fullDate
                )
            );
            return chain(groupedActivity)
                .map((items, group) => ({
                    group,
                    items,
                }))
                .value();
        },
    },
    mutations: {
        SET_ITEMS(state, payload) {
            state.items = payload;
        },
    },
    actions: {
        fetchItems: (context, payload = {}) => {
            payload.url = '/api/activityStream/activityStream';
            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_ITEMS', response.data);
                }
                return response;
            });
        }
    },
};
