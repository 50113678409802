import { getDetails } from '@/utils/auth';
import {
    isMatch,
    parseISO,
    format,
    formatRelative,
    differenceInDays,
    formatDistanceToNowStrict,
} from 'date-fns';
import { enGB, de } from 'date-fns/locale';

const locales = { enGB, de };

const dateTypes = {
    monthYear: 'LLLL yyyy',
    fullDate: 'dd. LLLL yyyy',
    fullDateMonthName: 'dd. LLLL yyyy',
    fullDateTime: 'dd. LLLL yyyy HH:mm:ss',
    shortDateTime: 'dd.LL.yy HH:mm',
    time: 'pp',
    datePicker: 'yyyy-LL-dd',
    fileDate: 'yyyy-LL-dd-HH-mm'
};

function getLang() {
    return getDetails() !== null ? getDetails().preferredLanguage.toLowerCase() : 'en';
}

var formatRelativeLocaleDetail = {
    lastWeek: "'lastWeek'",
    yesterday: "'yesterday'",
    today: "'today'",
    tomorrow: "'tomorrow'",
    nextWeek: 'eeee',
    other: dateTypes.fullDate,
};
var formatRelativeLocaleWeek = {
    lastWeek: "'lastWeek'",
    yesterday: "'yesterday'",
    today: "'today'",
    tomorrow: "'tomorrow'",
    nextWeek: 'eeee',
    other: dateTypes.monthYear,
};
var localeDetail = {};
var localeWeek = {};
if (getLang() === 'de') {
    localeDetail = {
        ...de,
        formatRelative: token => formatRelativeLocaleDetail[token],
    };
    localeWeek = {
        ...de,
        formatRelative: token => formatRelativeLocaleWeek[token],
    };
} else {
    localeDetail = {
        ...enGB,
        formatRelative: token => formatRelativeLocaleDetail[token],
    };
    localeWeek = {
        ...enGB,
        formatRelative: token => formatRelativeLocaleWeek[token],
    };
}
function formatDate(date, formatStr = 'PP') {
    return format(date, formatStr, {
        locale: locales[getLang()],
    });
}
function formatRelativeGroup(date, baseDate, groupType, dateType) {
    return Math.abs(differenceInDays(date, baseDate)) < 6
        ? formatRelative(date, baseDate, {
              locale: groupType === 'detail' ? localeDetail : localeWeek,
              weekStartsOn: 1,
          })
        : formatDate(date, dateType);
}

function formatDistanceFrom(date) {
    if (typeof date === 'string') {
        date = parseISO(date);
    }
    if (!isNaN(date.getMonth())) {
        return formatDistanceToNowStrict(date, {
            locale: locales[getLang()],
            addSuffix: true,
        });
    }
}

export {
    getLang,
    dateTypes,
    isMatch,
    parseISO,
    formatDate,
    formatRelative,
    formatRelativeGroup,
    formatDistanceFrom,
};
