// global custom directive called `v-clear-input-on-blur`
const clearInputOnBlur = {
    bind(el, binding, vnode) {
        if ((!Object.prototype.hasOwnProperty.call(binding, 'value')) || binding.value) {
            vnode.componentInstance.$on('blur', function() {
                this.$nextTick(() => {
                    vnode.componentInstance.lazySearch = null;
                });
            });
        }
    },
    unbind(el, binding, vnode) {
        vnode.componentInstance.$off('blur');
    },
};

export default clearInputOnBlur;
