import Vue from 'vue';
import http from '@/utils/axios';

export default {
    namespaced: true,
    state: {
        items: [],
        tagItems: [],
        categoryTagItems: [],
    },
    mutations: {
        SET_ITEMS(state, payload) {
            state.items = payload.items;
        },
        ADD_ITEM(state, payload) {
            state.items.unshift(payload.item);
        },
        REPLACE_ITEM_UUID_WITH_ID(state, payload) {
            const index = state.items.findIndex(x => x.id === payload.uuid);
            Vue.set(state.items[index], 'id', payload.newId);
        },
        EDIT_ITEM(state, payload) {
            const index = state.items.findIndex(x => x.id === payload.id);
            Vue.set(state.items, index, payload.item);
        },
        SET_STATE_ITEMS(state, payload) {
            state[payload.stateProperty] = payload.items;
        },
        UNSHIFT_STATE_ITEMS: (state, payload) => {
            state[payload.stateProperty].unshift(payload.item.data);
        },
        REPLACE_STATE_UUIDS_WITH_ID(state, payload) {
            state[payload.stateProperty] = state[payload.stateProperty].map(
                item => {
                    if (item.clientId) {
                        payload.items.forEach(newItem => {
                            if (item.clientId === newItem.clientId) {
                                item.id = newItem.id;
                            }
                        });
                    }
                    return item;
                }
            );
        },
        REMOVE_ITEM: (state, payload) => {
            const removedIndex = state.items.findIndex(
                x => x.id === payload.id
            );
            state.items.splice(removedIndex, 1);
        },
    },
    actions: {
        fetchFilterItems({ commit, getters }, payload) {
            const { storeMutation } = payload;
            const id = payload.id || null;
            const adminScope = payload.adminScope || false;
            const { limit } = payload;
            const { page } = payload;
            const searchTerm =
                payload.text && payload.text.length > 3 ? payload.text : null;
            const { searchCategory } = payload;
            const status = payload.status || null;
            const countries = payload.countries || null;
            // let countryFilter = (payload.countryFilter.length) ? payload.countryFilter : 'all'
            // let activeRelevanceFilter = payload.activeRelevanceFilter

            const query = {
                id,
                maxPerPage: limit,
                currentPage: page,
                text: searchTerm,
                category: searchCategory,
                status,
                countries,
                adminScope,
                // sort: ''
            };
            return new Promise((resolve, reject) =>
                http
                    .post('/api/content/filter', JSON.stringify(query))
                    .then(response => response.data)
                    .then(response => {
                        const { items } = response;
                        if (storeMutation) {
                            commit(storeMutation, { items });
                        }
                        resolve({
                            result:
                                storeMutation === 'SET_ITEMS'
                                    ? getters.getItems
                                    : response.items,
                            total: response.total,
                        });
                    })
                    .catch(err => {
                        console.warn(err);
                        reject(err);
                        throw new Error('Network exception');
                    })
            );
        },
        fetchLastFilterItems(context, payload) {
            const { limit } = payload;
            const { page } = payload;
            const countries = payload.countries || null;

            const query = {
                maxPerPage: limit,
                currentPage: page,
                countries,
            };
            return new Promise((resolve, reject) =>
                http
                    .post('/api/content/last', JSON.stringify(query))
                    .then(response => response.data)
                    .then(response => {
                        resolve({
                            result: response,
                        });
                    })
                    .catch(err => {
                        console.warn(err);
                        reject(err);
                        throw new Error('Network exception');
                    })
            );
        },
        addItem({ commit }, payload) {
            const postData = payload.item;
            // postData.tags = [1, 2]
            // postData.departmentCategory = 'public_relations'
            // postData.penaltyFeeCategory = 'legal_basis'

            return new Promise(resolve =>
                http
                    .post('/api/admin/content/create', JSON.stringify(postData))
                    .then(response => {
                        resolve(response);
                    })
                    .catch(() => {
                        throw new Error('Network exception');
                    })
                    .finally(() => {
                        commit('ADD_ITEM', payload);
                    })
            );
        },
        replaceItemUuidWithId: (context, payload) =>
            new Promise(resolve => {
                context.commit('REPLACE_ITEM_UUID_WITH_ID', payload);
                resolve();
            }),
        editItem({ commit }, payload) {
            return new Promise(resolve =>
                http
                    .post(
                        '/api/admin/content/edit',
                        JSON.stringify(payload.item)
                    )
                    .then(response => {
                        resolve(response);
                    })
                    .catch(() => {
                        throw new Error('Network exception');
                    })
                    .finally(() => {
                        commit('EDIT_ITEM', payload);
                    })
            );
        },
        fetchTagItems({ commit, state }) {
            if (!state.items.length) {
                return http('/api/tags/list')
                    .then(response => response.data)
                    .then(response => {
                        const newItems = [];
                        response.map(item => {
                            newItems.push(item);
                        });
                        const payload = {
                            items: newItems,
                            stateProperty: 'tagItems',
                        };
                        commit('SET_STATE_ITEMS', payload);
                    })
                    .catch(() => {
                        throw new Error('Network exception');
                    });
            }
        },
        unshiftTagItems: (context, item) => {
            const payload = {
                item,
                stateProperty: 'tagItems',
            };
            return new Promise(resolve => {
                context.commit('UNSHIFT_STATE_ITEMS', payload);
                resolve();
            });
        },
        replaceTagUuidsWithId: (context, items) => {
            const payload = {
                items,
                stateProperty: 'tagItems',
            };
            return new Promise(resolve => {
                context.commit('REPLACE_STATE_UUIDS_WITH_ID', payload);
                resolve();
            });
        },
        setTagItems: (context, items) => {
            const payload = {
                items,
                stateProperty: 'tagItems',
            };
            return new Promise(resolve => {
                context.commit('SET_STATE_ITEMS', payload);
                resolve();
            });
        },
        fetchCategoryTagItems({ commit, state }) {
            if (!state.items.length) {
                return http('/api/cms/categoryTags/list')
                    .then(response => response.data)
                    .then(response => {
                        const newItems = [];
                        response.map(item => {
                            newItems.push(item);
                        });
                        const payload = {
                            items: newItems,
                            stateProperty: 'categoryTagItems',
                        };
                        commit('SET_STATE_ITEMS', payload);
                    })
                    .catch(() => {
                        throw new Error('Network exception');
                    });
            }
        },
        unshiftCategoryTagItems: (context, item) => {
            const payload = {
                item,
                stateProperty: 'categoryTagItems',
            };
            return new Promise(resolve => {
                context.commit('UNSHIFT_STATE_ITEMS', payload);
                resolve();
            });
        },
        replaceCategoryTagUuidsWithId: (context, items) => {
            const payload = {
                items,
                stateProperty: 'categoryTagItems',
            };
            return new Promise(resolve => {
                context.commit('REPLACE_STATE_UUIDS_WITH_ID', payload);
                resolve();
            });
        },
        setCategoryTagItems: (context, items) => {
            const payload = {
                items,
                stateProperty: 'categoryTagItems',
            };
            return new Promise(resolve => {
                context.commit('SET_STATE_ITEMS', payload);
                resolve();
            });
        },
        deleteItem: (context, payload) =>
            new Promise(resolve =>
                http
                    .post('/api/admin/content/delete', JSON.stringify(payload))
                    .then(serverResponse => serverResponse.data)
                    .then(response => {
                        const objResponse = {
                            response,
                            id: payload.id,
                        };
                        context.commit('REMOVE_ITEM', objResponse);
                        resolve(objResponse);
                    })
                    .catch(() => {
                        throw new Error('Network exception');
                    })
            ),
    },
    getters: {
        getItems: state => state.items || [],
        getCategoryTagItems: state => state.categoryTagItems,
        getCategoryTagUuidItems: state =>
            state.categoryTagItems.filter(
                x => x.clientId && !x.id
            ),
        getTagItems: state => state.tagItems,
        getTagUuidItems: state =>
            state.tagItems.filter(x => x.clientId && !x.id),
    },
};
