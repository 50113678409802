import Vue from 'vue';

export default {
    namespaced: true,
    state: {
      recordsByCategory: [],
    },
    mutations: {        
        SET_CATEGORY_RECORDS(state, payload) {
          if (!state.recordsByCategory[payload.categoryId]) {
              Vue.set(
                  state.recordsByCategory,
                  payload.categoryId,
                  []
              );
          }
          Vue.set(
              state.recordsByCategory,
              payload.categoryId,
              payload.records
          );
      },
    },
    actions: {
        fetch(context, payload = {}) {
            payload.url = '/api/groupTemplates';
            const disableStoreUpdate = payload.disableStoreUpdate || false;
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage && context.state.itemsFetched) {
                return {data: context.getters.getItems};
            }
            payload.sortBy = 'updatedAt';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!disableStoreUpdate && !response.data.error) {
                    context.commit('SET_CATEGORY_RECORDS', {
                        categoryId: payload && payload.domains && payload.domains.length ? payload.domains[0] : 'all',
                        records: response.data,
                    });
                }
                return response;
            });
        },
    },
    getters: {
        getRecordsByCategory: state => categoryId => {
          if (
              !state.recordsByCategory ||
              !state.recordsByCategory[categoryId]
          ) {
              return [];
          }
          return state.recordsByCategory[categoryId];
      }
    },
};
