import Vue from 'vue';

export default {
    namespaced: true,
    state: {
      help: {},
    },
    mutations: {        
      SET_HELP(state, payload) {
        if (!Object.prototype.hasOwnProperty.call(state.help, payload.entity)) {
            Vue.set(state.help, payload.entity, {});
        }
        if (!Object.prototype.hasOwnProperty.call(state.help[payload.entity], payload.fieldName)) {
            Vue.set(state.help[payload.entity], payload.fieldName, {});
        }
        if (!Object.prototype.hasOwnProperty.call(payload, 'childFieldName') || payload.childFieldName === null) {
            Vue.set(state.help[payload.entity], payload.fieldName, payload);
        }
        if (Object.prototype.hasOwnProperty.call(payload, 'childFieldName') && payload.childFieldName !== null) {
            if (!Object.prototype.hasOwnProperty.call(state.help[payload.entity][payload.fieldName], payload.childFieldName)) {
                Vue.set(state.help[payload.entity][payload.fieldName], payload.childFieldName, {});
            }
            Vue.set(state.help[payload.entity][payload.fieldName], payload.childFieldName, payload);
        }

      },
      REMOVE_HELP(state, payload) {
            if (!Object.prototype.hasOwnProperty.call(payload, 'entity') || !Object.prototype.hasOwnProperty.call(payload, 'fieldName')) {
                return;
            }
            if (Object.prototype.hasOwnProperty.call(payload, 'childFieldName') &&  payload.childFieldName !== null && Object.prototype.hasOwnProperty.call(state.help[payload.entity][payload.fieldName], payload.childFieldName)) {
                Vue.delete(state.help[payload.entity][payload.fieldName], payload.childFieldName);
            } else {
                Vue.delete(state.help[payload.entity], payload.fieldName);
            }
      },
    },
    actions: {
        fetchList(context, payload = {}) {
            payload.url = '/api/companyGroupHelp';
            const disableStoreUpdate = payload.disableStoreUpdate || false;
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage && Object.keys(context.state.help).length) {
                return {data: context.state.help};
            }
            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!disableStoreUpdate && !response.data.error) {
                    response.data.forEach(x => context.commit('SET_HELP', x));
                }
                return response;
            });
        },
        fetchById: (context, payload) => {
            payload.url = '/api/companyGroupHelp/' + payload.id;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_HELP', response.data);
                }
                return response;
            });
        },
        create: (context, payload = {}) => {
            payload.url = '/api/admin/companyGroupHelp/create';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_HELP', response.data);
                }
                return response;
            });
        },
        edit: (context, payload = {}) => {
            payload.url = '/api/admin/companyGroupHelp/edit';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_HELP', response.data);
                }
                return response;
            });
        },
        delete: (context, payload = {}) => {
            payload.url = '/api/admin/companyGroupHelp/delete';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('REMOVE_HELP', payload);
                }
                return response;
            });
        },
    },
    getters: {
        getHelp: state => state.help,
        getByEntityAndFieldName: state => (entity, fieldName, childFieldName) => {
          if (
              !entity ||
              !fieldName ||
              !state.help ||
              !state.help[entity] ||
              !state.help[entity][fieldName]
          ) {
              return null;
          }
          if (childFieldName && Object.prototype.hasOwnProperty.call(state.help[entity], fieldName) && Object.prototype.hasOwnProperty.call(state.help[entity][fieldName], childFieldName)) {
            return state.help[entity][fieldName][childFieldName];
          }
          if (Object.prototype.hasOwnProperty.call(state.help[entity], fieldName) && Object.prototype.hasOwnProperty.call(state.help[entity][fieldName], 'id')) {
            return state.help[entity][fieldName];
          } 
          return null;
      }
    },
};
