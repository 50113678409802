const MoveItem = {
    install(Vue) {
        Vue.move = function(arr, pos1, pos2) {
            // local variables
            let i;
            let tmp;
            // cast input parameters to integers
            pos1 = parseInt(pos1, 10);
            pos2 = parseInt(pos2, 10);
            // if positions are different and inside array
            if (
                pos1 !== pos2 &&
                pos1 >= 0 &&
                pos1 <= arr.length &&
                pos2 >= 0 &&
                pos2 <= arr.length
            ) {
                // save element from position 1
                tmp = arr[pos1];
                // move element down and shift other elements up
                if (pos1 < pos2) {
                    for (i = pos1; i < pos2; i++) {
                        arr[i] = arr[i + 1];
                    }
                } else {
                    // move element up and shift other elements down
                    for (i = pos1; i > pos2; i--) {
                        arr[i] = arr[i - 1];
                    }
                }
                // put element from position 1 to destination
                Vue.set(arr, pos2, tmp);
            }
        };
    },
};

export default MoveItem;
