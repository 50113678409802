export default {
    namespaced: true,
    state: {
        items: [],
        countryStates: [],
        itemsFetched: false
    },
    mutations: {
        SET_FETCHED(state, reset = false) {
            state.itemsFetched = !reset;
        },
        SET_ITEMS(state, items) {
            state.items = items;
        },
        SET_COUNTRY_STATES(state, items) {
            state.countryStates = items;
        },
    },
    actions: {
        fetch(context, payload = {}) {
            payload.url = '/api/countries';
            const disableStoreUpdate = payload.disableStoreUpdate || false;
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage && context.state.itemsFetched) {
                return {data: context.getters.getItems};
            }
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!disableStoreUpdate && !response.data.error) {
                    context.commit('SET_FETCHED');
                    context.commit('SET_ITEMS', response.data);
                }
                return response;
            });
        },
        fetchCountryStates(context, payload = {}) {
            payload.url = '/api/countries/states';
            const disableStoreUpdate = payload.disableStoreUpdate || false;
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage && context.state.countryStates.length) {
                return {data: context.getters.getItems};
            }
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!disableStoreUpdate && !response.data.error) {
                    context.commit('SET_COUNTRY_STATES', response.data);
                }
                return response;
            });
        },
        clearItems({ commit }) {
            commit('SET_FETCHED', true);
            commit('SET_ITEMS', []);
        },
    },
    getters: {
        findMultipleByCountryCodes: (state, getters) => arrCountryCodes =>
            arrCountryCodes.map(countryCode =>
                getters.findByCountryCode(countryCode.toUpperCase())
            ),
        findByCountryCode: state => countryCode => {
            if (typeof countryCode === 'object') {
                return countryCode;
                // TODO:: sometimes there is an object (PL), which breaks the filter for this country
            }
            return state.items.find(
                x => x.countryCode === countryCode.toUpperCase()
            );
        },
        findByIsoAlpha3: state => isoAlpha3 => state.items.find(x => x.isoAlpha3 === isoAlpha3),
        getCountryById: state => id => state.items.find(x => x.id === id),
        getEuCountries: state => state.items.filter(x => x.eu),
        getCountryStates: state => state.countryStates,
        getItems: state => {
            const alphabeticalSort = (a,b) => {
                if(a.countryName.toLowerCase() < b.countryName.toLowerCase()) { return -1; }
                if(a.countryName.toLowerCase() > b.countryName.toLowerCase()) { return 1; }
                return 0;
            }
            const euCountries = state.items.filter(x => {
            return !!x.eu === true
            }).sort(alphabeticalSort);
            
            const nonEuCountries = state.items.filter(x => {
            return !!x.eu === false
            }).sort(alphabeticalSort);

            return [...euCountries, ...nonEuCountries];
        },
    },
};
