<template>
    <v-dialog v-model="model" :max-width="calcedMaxWidth" :width="calcedWith" scrollable :persistent="persistent">
        <v-card>
            <v-card-title
                class="text-h5 px-3"
                :class="errorDialog ? 'error--text' : 'success--text'"
            >
                <span v-text="headline" />
            </v-card-title>
            <v-card-subtitle class="px-3">
                <p class="mt-3 mb-0 pb-0">{{ message }}</p>
                <template v-if="errorDialog && errorCodeMessage">
                    <br /><br /><small>{{ errorCodeMessage }}</small>
                </template>
                <template v-if="supportId">
                    <br /><br />
                    <div @click="copy(supportId)" class="text-justify">
                        <div>{{ $t('errMsgSupportIdPrefix') }}</div>
                        <div class="white my-3 pa-3 text-center cursor-copy">
                            {{ supportId }}
                        </div>
                        <div>{{ $t('errMsgSupportIdSuffix') }}</div>
                    </div>
                </template>
            </v-card-subtitle>
            <v-divider v-if="items && items.length"></v-divider>
            <v-card-text class="pa-0">
                <v-list v-if="items && items.length" two-line dense tile>
                    <v-list-item-group color="primary">
                        <v-list-item v-for="(item, i) in items" :key="i" selectable>
                            <v-list-item-content>
                                <v-list-item-title
                                    class="primary--text"
                                    v-text="item.title"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                    v-if="item.subtitle"
                                    v-text="item.subtitle"
                                ></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn
                                    v-if="item.route"
                                    icon
                                    tag="a"
                                    :to="item.route"
                                    target="_blank"
                                    :disabled="persistent"
                                >
                                    <v-icon color="primary">mdi-open-in-new</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
            <v-divider v-if="items && items.length" class="mb-2"></v-divider>
            <v-card-actions>
                <slot
                    name="actions"
                ></slot>
            </v-card-actions>
            <v-card-actions>
                <v-spacer />
                    <v-btn
                        color="grey"
                        text
                        @click.stop="model = false"
                        :disabled="persistent"
                        >{{ closeText }}</v-btn
                    >
            </v-card-actions>
        </v-card>
        <v-snackbar
            v-model="snackbar"
            color="success"
            :timeout="6000"
            vertical
            top
        >
            {{ $t('copiedToClipboard') }}
            <v-btn dark text @click="snackbar = false">
                {{ $t('close') }}
            </v-btn>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import { copyTextToClipboard } from '@/utils/clipboard';

export default {
    name: 'BaseResponseDialog',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        errorDialog: {
            type: Boolean,
            default: false,
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        headline: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: null,
        },
        errorCodeMessage: {
            type: String,
            default: null,
        },
        items: {
            type: Array,
            default: () => [],
        },
        maxWidth: {
            type: [Number, String],
            default: 400,
        },
        supportId: {
            type: String,
            default: null,
        },
        btnCloseText: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            snackbar: false,
        };
    },
    computed: {
        closeText() {
            if(!this.btnCloseText) {
                return this.$t('cancel');
            }
            return this.$te(this.btnCloseText) ? this.$t(this.btnCloseText) : this.btnCloseText;
        },
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        calcedMaxWidth () {
            return this.$vuetify.breakpoint.smAndDown ? '100%' : 800;
        },
        calcedWith () {
            return this.$vuetify.breakpoint.lgAndUp ? 800 : this.$vuetify.breakpoint.smAndDown ? '1000%' : '80%';
        }
    },
    methods: {
        copy(str) {
            copyTextToClipboard(str);
            this.snackbar = true;
        },
    },
};
</script>
