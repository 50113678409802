<template>
    <v-card
        @click.passive="emitHelpMode"
        @focus.capture="emitHelpMode"
        height="100%"
        elevation="1"
    >
        <v-card-text class="pa-0">
            <v-input
                :hint="hint ? hint : false"
                :persistent-hint="items && items.length && hint ? true : false"
                class="v-multi-options pa-3"
                :error-messages="errors.first(fieldName + rowId, formScope)"
                hide-details="auto"
            >
                <template v-slot:prepend>
                    <v-sheet class="pb-3">
                        <label
                            v-if="label"
                            class="v-label v-label--active theme--light"
                            v-text="label"
                        />
                    </v-sheet>
                </template>
                <template v-slot:default>
                    <div class="v-select__slot">
                        <v-item-group
                            :mandatory="required"
                            :error-messages="
                                errors.collect(formScope + '.' + fieldName + rowId)
                            "
                            :name="fieldName + rowId"
                            :data-vv-name="fieldName + rowId"
                            :data-vv-scope="formScope"
                            v-validate="
                                required && validationRules
                                    ? validationRules
                                    : false
                            "
                            :multiple="multiple"
                            :value="model"
                            class="w100"
                        >
                            <v-row v-if="items && items.length" align="center" justify="center">
                                <v-col
                                    v-for="(item, index) in items"
                                    :key="item.id || item.clientId"
                                >
                                    <v-item>
                                        <v-card
                                            :class="
                                                isActive(index)
                                                    ? 'v-card--active font-weight-strong'
                                                    : ''
                                            "
                                            :color="
                                                isActive(index)
                                                    ? 'secondary'
                                                    : 'secondary lighten-5'
                                            "
                                            class="default"
                                            height="100%"
                                            outlined
                                            :disabled="disabled"
                                            @click.stop="
                                                changeValue(
                                                    index,
                                                    item,
                                                    isActive(index)
                                                )
                                            "
                                        >
                                            <v-card-text>
                                                <v-scroll-y-transition
                                                    v-if="!multiple"
                                                >
                                                    <v-icon
                                                        v-if="isActive(index)"
                                                        color="primary"
                                                        style="margin-top: -3px;"
                                                        >radio_button_checked</v-icon
                                                    >
                                                    <v-icon
                                                        v-else
                                                        color="primary"
                                                        style="margin-top: -3px;"
                                                        >radio_button_unchecked</v-icon
                                                    >
                                                </v-scroll-y-transition>
                                                <v-scroll-y-transition v-else>
                                                    <v-icon
                                                        v-if="isActive(index)"
                                                        color="primary"
                                                        style="margin-top: -3px;"
                                                        >check_box</v-icon
                                                    >
                                                    <v-icon
                                                        v-else
                                                        color="primary"
                                                        style="margin-top: -3px;"
                                                        >check_box_outline_blank</v-icon
                                                    >
                                                </v-scroll-y-transition>
                                                <span
                                                class="pl-3 text-subtitle-1"
                                                :class="
                                                isActive(index)
                                                    ? 'font-weight-bold'
                                                    : ''
                                            ">{{ item.title }}</span>
                                            </v-card-text>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>
                            <div v-else>
                                <v-alert
                                    type="warning"
                                    outlined
                                    border="left"
                                    v-if="noItemsMessage"
                                    >{{ noItemsMessage }}</v-alert
                                >
                            </div>
                        </v-item-group>
                    </div>
                </template>
            </v-input>
        </v-card-text>
    </v-card>
</template>

<script>
import ModelMixin from '@/components/vvt/ModelMixin';

export default {
    name: 'BaseInputMultipleOption',
    mixins: [ModelMixin],
    inject: {
        $validator: '$validator',
    },
    props: {
        value: {
            type: [Number, Array],
            default: null,
        },
        items: {
            type: Array,
            default: () => [],
        },
        formScope: {
            type: String,
            default: 'defaultScope',
        },
        fieldName: {
            type: String,
            default: null,
        },
        childFieldName: {
            type: String,
            default: null,
        },
        helpModeText: {
            type: String,
            default: null,
        },
        itemsHelpMode: {
            type: Array,
            default: () => [],
        },
        groupName: {
            type: String,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
        label: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        validationRules: {
            type: String,
            default: 'required|min_value:0',
        },
        itemText: {
            type: String,
            default: 'title',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        noItemsMessage: {
            type: String,
            default: null,
        },
        workflowMode: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        rowId: {
            type: [Number, String],
            default: 1
        },
    },
    data() {
        return {
            model: this.getIndexedValueFromIdValue(this.items, this.value),
        };
    },
    methods: {
        isActive(index) {
            return this.multiple
                ? this.model && this.model.includes(index)
                : this.model === index;
        },
        changeValue(index, item, currentState) {
            if (this.multiple) {
                if (!currentState) {
                    if (!this.model.includes(index)) {
                        this.model.push(index);
                    }
                } else {
                    this.model.splice(
                        this.model.findIndex(x => x === index),
                        1
                    );
                }
            } else {
                this.model = index;
            }
            const data = this.setIndexedValueFromIdValue(
                this.items,
                this.model
            );
            this.$emit('input', data);
            if (this.itemsHelpMode && this.itemsHelpMode.length) {
                let helpModeContent = this.itemsHelpMode.find(x => x.id === data);
                this.$emit('help-mode', {
                    title: helpModeContent.title,
                    text: helpModeContent.helpMode,
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName + '_' + this.model
                });
            }
        },
        emitHelpMode () {
            if (this.helpModeText) {
                this.$emit('help-mode', {
                    title: this.label,
                    text: this.helpModeText,
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName
                })
            } else {
                this.$emit('help-mode', {
                    step: this.step,
                    name: this.fieldName,
                    group: this.groupName,
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName
                })
            }
        }
    },
    mounted() {
        if (!this.items || this.items.length === 0) {
            console.warn(
                'BaseInputMultiOption.vue: items property should not be empty!'
            );
        }
        // this.model = this.getIndexedValueFromIdValue(this.items, this.value)
    },
    watch: {
        items() {
            this.model = this.getIndexedValueFromIdValue(this.items, this.value);
        },
        value() {
            this.model = this.getIndexedValueFromIdValue(this.items, this.value);
        }
    },
};
</script>

<style lang="scss">
.v-multi-options .v-input__slot {
    flex-direction: column;
    align-items: stretch;
}
.v-multi-options .v-input__slot label {
    transform: translateY(-24px) translateX(-30px) scale(0.75);
}
.v-multi-options .v-messages {
    padding-top: 18px;
}
</style>
