export default {
    namespaced: true,
    state: {
      settings: {},
    },
    mutations: {        
      SET_COMPANY_SETTINGS(state, payload) {
        state.settings = {...state.settings, ...payload};
      },
    },
    actions: {
      fetch(context, payload = {}) {
          payload.url = '/api/company-group/settings';
          return context.dispatch('get', payload, { root: true }).then((response) => {
              if (!response.data.error) {
                  context.commit('SET_COMPANY_SETTINGS', response.data);
              }
              return response;
          });
      },
      save(context, payload = {}) {
          payload.url = '/api/company-group/settings';
          payload = {...context.state.settings, ...payload};
          return context.dispatch('post', payload, { root: true }).then((response) => {
              if (!response.data.error) {
                  context.commit('SET_COMPANY_SETTINGS', response.data);
              }
              return response;
          });
      },
    },
    getters: {
        getAll: state => {
          return state.settings;
        },
        get: state => setting => {
          if (
              !state.settings ||
              !state.settings[setting]
          ) {
              return null;
          }
          return state.settings[setting];
      }
    },
};
