import Vue from 'vue';
import router from '@/router';

export default {
    namespaced: true,
    state: {
        allCompanies: [],
        filterResultCompanies: {},
    },
    getters: {
        getAllCompanies: state => state.allCompanies,
        getMultipleById: state => arrIds => state.allCompanies.filter(x => arrIds.includes(x.id)),
        getById: state => companyId => state.allCompanies.find(x => x.id === companyId),
        getFilteredCompanies: state => filterId =>
            state.filterResultCompanies && state.filterResultCompanies[filterId]
                ? state.filterResultCompanies[filterId]
                : [],
    },
    mutations: {
        SET_ALL_COMPANIES(state, payload) {
            state.allCompanies = payload;
        },
        SET_FILTER_RESULT_COMPANIES(state, payload) {
            Vue.set(
                state.filterResultCompanies,
                payload.filterId,
                payload.response
            );
        },
        ADD_COMPANY(state, payload){          
            const companyIndex = state.allCompanies.findIndex(x => x.id === payload.data.companyId);
            if(companyIndex <= -1) {
                state.allCompanies.push(payload.data);
            }
        },
        UPDATE_COMPANY(state, payload){
            const keys = Object.keys(state.filterResultCompanies);
            for (const key of keys) {
                const companyIndex = state.filterResultCompanies[key].findIndex(x => x.id === payload.data.companyId);
                if(companyIndex > -1) {
                    const newData = {
                        ...state.filterResultCompanies[key][companyIndex],
                        ... {
                            city: payload.data.responsibleCity,
                            countryCode: payload.data.responsibleCountryId,
                            countryId: payload.data.responsibleCountryIsoNumeric,
                            countryName: payload.data.responsibleCountryName,
                            latitude: payload.data.responsibleCountryLatitude,
                            longitude: payload.data.responsibleCountryLongitude,
                            postal: payload.data.responsiblePostcode,
                            street: payload.data.responsibleStreet,
                            title: payload.data.companyName,
                            phone: payload.data.companyPhone,
                            fax: payload.data.companyFax,
                            email: payload.data.companyEmail,
                            website: payload.data.companyWebsite,
                        }
                    };
                    Vue.set(state.filterResultCompanies[key], companyIndex, newData);
                }
            }
            
            const companyIndex = state.allCompanies.findIndex(x => x.id === payload.data.companyId);
            if(companyIndex > -1) {
                const newData = {
                    ...state.allCompanies[companyIndex],
                    ... {
                        city: payload.data.responsibleCity,
                        countryCode: payload.data.responsibleCountryId,
                        countryId: payload.data.responsibleCountryIsoNumeric,
                        countryName: payload.data.responsibleCountryName,
                        latitude: payload.data.responsibleCountryLatitude,
                        longitude: payload.data.responsibleCountryLongitude,
                        postal: payload.data.responsiblePostcode,
                        street: payload.data.responsibleStreet,
                        title: payload.data.companyName,
                        phone: payload.data.companyPhone,
                        fax: payload.data.companyFax,
                        email: payload.data.companyEmail,
                        website: payload.data.companyWebsite,
                    }
                };
                Vue.set(state.allCompanies, companyIndex, newData);
            }
        }
    },
    actions: {
        fetchById: (context, payload = {}) => {
            payload.url = '/api/company/' + payload.id;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                return response;
            });
        },
        create: (context, payload = {}) => {
            payload.url = '/api/admin/company/create';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('ADD_COMPANY', response);
                    context.dispatch('dashboard/addCompanyCount', null, { root: true });
                }
                return response;
            });
        },
        edit: (context, payload = {}) => {
            payload.url = '/api/admin/company/edit';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('UPDATE_COMPANY', response);
                }
                return response;
            });
        },
        fetchAll(context, payload = {}) {
            payload.url = '/api/filter/companies/all';
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if(!force && !forcedLanguage && context.getters.getAllCompanies && context.getters.getAllCompanies.length) {
                return {data: context.getters.getAllCompanies};
            }
            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_ALL_COMPANIES', response.data);
                }
                return response;
            });          
        },
        fetchByFilter(context, payload = {}) {
            payload.url = '/api/filter/companiesByFilter';
            let route = router && router.history && router.history.current ? router.history.current : null;
            if(route && route.query) {
                if(route.query.filterAlias) {
                    payload.filterAlias = route.query.filterAlias;
                }
                if(route.query.filterValue) {
                    payload.filterValue = route.query.filterValue;
                }
            }

            if (!Object.prototype.hasOwnProperty.call(payload, 'filterId')) {
                payload.filterId = 'all';
            }
            const force = payload.force || null;
            const forcedLanguage = payload.forcedLanguage || null;
            if (
                !force && !forcedLanguage &&
                context.state.filterResultCompanies &&
                context.getters.getFilteredCompanies(payload.filterId).length
            ) {
                return {
                    data: context.getters.getFilteredCompanies(payload.filterId)
                }
            }

            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_FILTER_RESULT_COMPANIES', {
                        filterId: payload.filterId,
                        response: response.data,
                    });
                }
                return response;
            });
        },
    }
}
