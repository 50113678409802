import Vue from 'vue';

export default {
    namespaced: true,
    state: {
      notifications: [],
    },
    mutations: {        
      CREATE_NOTIFICATION: (state, payload) => {
        state.notifications[payload.alias] = payload;
      },
      EDIT_NOTIFICATION(state, payload) {
        if (state.notifications[payload.alias]) {
          state.notifications[payload.alias] = payload;
        }
      },
      SET_NOTIFICATIONS(state, payload) {
        state.notifications = payload;
      },
      REMOVE_NOTIFICATION(state, payload) {
          Vue.delete(state.notifications, payload.alias);
      },
    },
    actions: {
        fetchList(context, payload = {}) {
            payload.url = '/api/company-group-notification';
            return context.dispatch('get', payload, { root: true }).then((response) => {
                return response;
            });
        },
        fetchByAlias(context, payload = {}) {
            payload.url = '/api/company-group-notification/alias/' + payload.alias;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_NOTIFICATIONS', response.data);
                }
                return response;
            });
        },
        fetchById(context, payload = {}) {
            payload.url = '/api/company-group-notification/' + payload.id;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_NOTIFICATIONS', response.data);
                }
                return response;
            });
        },
        create: (context, payload = {}) => {
            payload.url = '/api/admin/company-group-notification/create';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('CREATE_NOTIFICATION', response.data);
                }
                return response;
            });
        },
        edit: (context, payload = {}) => {
            payload.url = '/api/admin/company-group-notification/edit';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('EDIT_NOTIFICATION', response.data);
                }
                return response;
            });
        },
        delete: (context, payload = {}) => {
            payload.url = '/api/admin/company-group-notification/delete';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('REMOVE_NOTIFICATION', response.data);
                }
                return response;
            });
        },
    },
    getters: {
        getByAlias: state => (alias) => {
          if (
              !state.notifications ||
              !state.notifications[alias]
          ) {
              return null;
          }
          return state.notifications[alias];
      }
    },
};
