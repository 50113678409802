import Vue from 'vue';
import Vuex from 'vuex';
import http from '@/utils/axios';
import apps from './apps';
import mercure from './mercure';
import tasks from './tasks';
import languageSettings from './settings/language';
import clipboard from './clipboard';
import companyInternalKeywords from './company/companyInternalKeywords';
import authStore from './authStore';
import countries from './countries';
import dashboard from './dashboard';
import community from './community';
import companies from './company/companies';
import contactItemCategories from './vvt/contactItemCategories';
import domain from './domain';
import vendor from './vendor';
import companyDepartments from './company/companyDepartments';
import companyGroupDepartments from './companyGroup/companyGroupDepartments';
import companyGroupHelp from './companyGroup/companyGroupHelp';
import companyGroupNotification from './companyGroup/companyGroupNotification';
import companyLanguage from './vvt/companyLanguage';
import departmentCategories from './departmentCategories';
import companyProcessingActivities from './company/companyProcessingActivities';
import groupTemplates from './companyGroup/groupTemplates';
import legitimateInterest from './vvt/legitimateInterest';
import processingActivityModel from './vvt/processingActivityModel';
import tools from './vvt/tools';
import legality from './vvt/legality';
import nationalLegalObligation from './vvt/nationalLegalObligation';
import recipientNationalLegalObligation from './vvt/recipientNationalLegalObligation';
import nationalDataProtectionLaw from './vvt/nationalDataProtectionLaw';
import flexibilityClause from './vvt/flexibilityClause';
import groupsOfPersons from './vvt/groupsOfPersons';
import personalData from './vvt/personalData';
import personalDataCategories from './vvt/personalDataCategories';
import dataSource from './vvt/dataSource';
import deletionPractise from './vvt/deletionPractise';
import internalRecipientCategories from './vvt/internalRecipientCategories';
import externalRecipientCategories from './vvt/externalRecipientCategories';
import appropriateSafeguards from './vvt/appropriateSafeguards';
import blacklist from './vvt/blacklist';
import tom from './tom';
import companyTom from './company/companyTom';
import templates from './vvt/templates';
import eventStore from './eventStore';
import filter from './vvt/filter';
import staff from './staff';
import activityStream from './activityStream';
import companyGroupSettings from './companyGroup/companyGroupSettings';

Vue.use(Vuex);

export const store = new Vuex.Store({
    // strict: !process.env.VUE_APP_DISABLE_INTERCEPTORS,
    modules: {
        authStore,
        apps,
        mercure,
        tasks,
        languageSettings,
        clipboard,
        companyInternalKeywords,
        countries,
        dashboard,
        activityStream,
        companies,
        contactItemCategories,
        domain,
        vendor,
        companyDepartments,
        companyGroupDepartments,
        companyGroupHelp,
        companyGroupNotification,
        companyLanguage,
        departmentCategories,
        companyProcessingActivities,
        groupTemplates,
        legitimateInterest,
        community,
        processingActivityModel,
        tools,
        legality,
        nationalLegalObligation,
        recipientNationalLegalObligation,
        nationalDataProtectionLaw,
        flexibilityClause,
        groupsOfPersons,
        personalData,
        personalDataCategories,
        dataSource,
        deletionPractise,
        internalRecipientCategories,
        externalRecipientCategories,
        appropriateSafeguards,
        blacklist,
        tom,
        companyTom,
        templates,
        eventStore,
        filter,
        staff,
        companyGroupSettings,
    },
    actions: {
        get(context, payload = {}) {
            const url = payload.url || null;
            const forcedLanguage = payload.forcedLanguage || null;

            const fetchMasterItems = (payload && payload.fetchMasterItems) ? {'Fetch-Master-Items': true} : {};
            let options = forcedLanguage ? {headers: {...{ 'Accept-Language': `${forcedLanguage}` }, ...fetchMasterItems }} : {headers: fetchMasterItems};
            options = {...options, ...{
                validateStatus(status) {
                    return [412, 200, 204].includes(status);
                },
            }}
            
            return new Promise((resolve, reject) =>
                http(url, options)
                    .then(response => {
                        if (response.data.error) {
                            response.status = response.data && response.data.status ? response.data.status : 412;
                        }
                        resolve(response);
                    })
                    .catch(e => {
                        reject(e);
                        throw new Error(e);
                    })
            );
        },
        post(context, payload = {}) {
            const url = payload.url || null;
            const forcedLanguage = payload.forcedLanguage || null;

            const fetchMasterItems = (payload && payload.fetchMasterItems) ? {'Fetch-Master-Items': true} : {};
            let options = forcedLanguage ? {headers: {...{ 'Accept-Language': `${forcedLanguage}` }, ...fetchMasterItems }} : {headers: fetchMasterItems};
            options = {...options, ...{
                validateStatus(status) {
                    return [412, 200, 204].includes(status);
                },
            }}
            
            if (payload) {
                delete payload.force;
                delete payload.url;
                delete payload.disableStoreUpdate;
                delete payload.fetchMasterItems;
                delete payload.forcedLanguage;
            }
            return new Promise((resolve, reject) =>
                http.post(url, payload, options)
                    .then(response => {
                        if (response.data.error) {
                            response.status = response.data && response.data.status ? response.data.status : 412;
                        }
                        resolve(response);
                    })
                    .catch(e => {
                        reject(e);
                        throw new Error(e);
                    })
            );
        }
    }
});
