export default {
    namespaced: true,
    state: {
        items: [],
    },
    mutations: {
        SET_ITEMS(state, items) {
            state.items.push(items);
        },
    },
    actions: {
        fetchById: (context, payload) => {
            payload.url = '/api/companyTom/companyTom/' + payload.id;
            return context.dispatch('get', payload, { root: true }).then((response) => {
                context.commit('SET_ITEMS', response.data);
                return response;
            }, (reason) => {
                throw new Error(reason);
            });
        },
        edit: (context, payload = {}) => {
            payload.url = '/api/admin/companyTom/companyTom';
            return context.dispatch('post', payload, { root: true }).then((response) => {
                return response;
            });
        },
    },
    getters: {
        getByCompanyId: (state, getters, rootState, rootGetters) => id => {
            const items = state.items.find(x => x.companyId === parseInt(id));
            if (items && items.documentations) {
                items.documentations.map(x => {
                    if (x.documentationId) {
                        const documentation = rootGetters[
                            'tom/getDocumentationById'
                        ](x.documentationId);
                        x.name = documentation.name;
                    }
                    return x;
                });
            }
            return items || {};
        },
    },
};
