export default {
    namespaced: true,
    state: {
        items: [],
    },
    mutations: {
        SET_ITEMS(state, items) {
            state.items = items;
        },
    },
    actions: {
        fetch(context, payload = {}) {
            payload.url = '/api/departmentCategories';
            const forcedLanguage = payload.forcedLanguage || null;
            if(!forcedLanguage && context.state.items && context.state.items.length) {
                return {data: context.getters.getItems};
            }

            return context.dispatch('get', payload, { root: true }).then((response) => {
                if (!response.data.error) {
                    context.commit('SET_ITEMS', response.data);
                }
                return response;
            });
        },
        clearItems({ commit }) {
            commit('SET_ITEMS', []);
        },
    },
    getters: {
        findAll: state => state.items,
        getItems: state => state.items,
        findById: state => id => state.items.find(x => x.id === id),
    },
};
