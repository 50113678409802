import Vue from 'vue';

import Vuetify from 'vuetify/lib';

// import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'

// import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify);
export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        dark: false,
        default: 'light',
        disable: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#3073a3',
                secondary: '#fbfbfb',
                tertiary: '#f5f5f5',
                accent: '#6a91ad',
                application: '#ffffff',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
            },
            dark: {
                primary: '#3073a3',
                secondary: '#424242',
                tertiary: '#2f2f2f',
                accent: '#566d7d',
                application: '#363636',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
            },
        },
    },
});
