import Vue from 'vue';
import { v4 as uuid } from 'uuid';
import { flatten } from 'lodash';
import { eventBus } from '@/event-bus.js';
import { isUUID } from '@/components/vvt/ModelMixin.js';

const modelTemplate = {
    id: null,
    canRead: true,
    canEdit: true,
    canDelete: false,
    canDeleteWithoutPrecondition: false,
    canEditExtended: false,
    canEditWorkflow: false,
    company: null,
    template: false,
    parentTemplateId: null,
    leaMaster: false,
    name: null,
    language: null,
    prevLanguage: null,
    description: null,
    processDocumentation: null,
    department: null,
    domain: null,
    departmentCategory: [],
    contactItems: [],
    countriesOfAffectedPersons: [],
    internalId: null,
    status: 'draft',
    authorityReportRelevance: null,
    controller: true,
    processor: false,
    processorCompanies: [],
    processorVendorCompanies: [],
    processorOutsideCompanies: null,
    priority: null,
    internalKeywords: [],
    tools: [],
    groupsOfPersons: [],
    purposeOfProcessing: [],
    deletionPractise: [],
    internalRecipients: [],
    corporationRecipients: [],
    externalRecipients: [],
    createdAt: null,
    updatedAt: null,
    tom: {
        isSet: false,
        documentations: [],
        pseudonymisationMeasures: [],
        encryptionMeasures: [],
        confidentialityMeasures: {
            accessControl: [],
            systemControl: [],
            volumeControl: [],
            separationControl: [],
        },
        integrityMeasures: {
            dataIntegrityGuarantee: [],
            transmissionControl: [],
            transportControl: [],
            inputControl: [],
        },
        availabilityAndResilienceMeasures: {
            availabilityControl: [],
            recoverability: [],
            reliability: [],
        },
        evaluationOfDataProcessingSecurityMeasures: {
            reviewProcess: [],
            orderControl: [],
        },
        furtherMeasures: null,
    },
    riskQuestionMatrix: {
        currentQuestion: 1,
        result: null,
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        question5: null,
        question6: null,
        question7: null,
        question8: null,
        question9: null,
    },
    blacklistQuestionResult: [],
    riskBlacklist: null,
    riskAssessment: {
        riskyDataProcessing: null,
        riskWithMeasures: null,
    },
    similarProcessingActivity: {
        title: null,
        description: null,
    },
    dpiaRequirement: null,
    riskAssessmentNote: null,
    dpiaNote: null,
    dpiaCompleted: null,
    enclosures: [],
    resubmission: null,
    dpiaBlacklistEntries: [],
    companyAssignment: []
};

const groupOfPersonsTemplate = {
    group: {
        id: null,
        title: '',
    },
    source: null,
    infoObligationsFulfilled: null,
    infoObligationNotes: null,
    personalDataCategory: [],
    personalData: [],
    encryptedPersonalData: [],
    pseudonymisedPersonalData: [],
    anonymisedPersonalData: [],
    dataOfMinors: null,
    dataSecurity: false,
    generalComments: null,
    internalComments: null,
};

const purposeOfProcessingTemplate = {
    purpose: {
        id: null,
        title: '',
    },
    legality: null,
    legalityNote: null,
    legalityEnclosure: [],
    nationalLegalObligation: null,
    flexibilityClause: [],
    nationalDataProtectionLaw: [],
    legitimateInterest: null,
    generalComments: null,
    internalComments: null,
};

const deletionPractiseTemplate = {
    groupsOfPersons: [],
    deletionPractiseGdpr: [],
    deletionPractiseNational: [], //deprecated
    personalData: [],
    personalDataCategory: [],
    enclosure: [],
    generalComments: null,
    internalComments: null,
};

const internalRecipientTemplate = {
    typeOfRecipient: [],
    department: [],
    groupsOfPersons: [],
    personalData: [],
    personalDataCategory: [],
    generalComments: null,
    internalComments: null,
};

const corporationRecipientTemplate = {
    corporationCompany: null,
    processingType: null,
    groupsOfPersons: [],
    personalData: [],
    personalDataCategory: [],
    legality: null,
    nationalLegalObligation: null,
    flexibilityClause: [],
    nationalDataProtectionLaw: [],
    appropriateSafeguards: [],
    legitimateInterest: null,
    purpose: {
        id: null,
        title: '',
    },
    subcontractors: null,
    lcm: null,
    avvUpload: [],
    dataTransferSecurity: false,
    generalComments: null,
    internalComments: null,
};

const externalRecipientTemplate = {
    vendor: null,
    typeOfRecipient: null,
    processingType: null,
    groupsOfPersons: [],
    personalData: [],
    personalDataCategory: [],
    legality: null,
    nationalLegalObligation: null,
    flexibilityClause: [],
    nationalDataProtectionLaw: [],
    country: null,
    appropriateSafeguards: [],
    legitimateInterest: null,
    company: {
        id: null,
        title: '',
    },
    jointControllerAddress: {
        street: null,
        postcode: null,
        city: null,
    },
    purpose: {
        id: null,
        title: '',
    },
    subcontractors: null,
    lcm: null,
    avvUpload: [],
    dataTransferSecurity: false,
    generalComments: null,
    internalComments: null,
};
const legitimateInterestTemplate = {
    id: null,
    title: null,
    interestResponsiblePerson: null,
    interestPersonConcerned: null,
    necessity: null,
    balancingOfInterests: null,
};
purposeOfProcessingTemplate.legitimateInterest = legitimateInterestTemplate;
corporationRecipientTemplate.legitimateInterest = legitimateInterestTemplate;
externalRecipientTemplate.legitimateInterest = legitimateInterestTemplate;

const enclosureTemplate = {
    title: null,
    description: null,
    files: [],
};

export default {
    namespaced: true,
    state: {
        editorMode: null,
        model: JSON.parse(JSON.stringify(modelTemplate)),
        helpModeState: false,
        workflowModeState: false
    },
    getters: {
        getEditorMode: state => state.editorMode,
        getModel: state => state.model,
        getProperty: state => property => state.model[property],
        getChildProperty: state => payload => {
            return state.model[payload.property] && state.model[payload.property][payload.child]
                    ? state.model[payload.property][payload.child]
                    : null;
        },
        getBlacklistAnswer: state => payload => {
            const blacklistQuestion = state.model.blacklistQuestionResult.find(x => x.blacklistQuestionId === payload);
            if (blacklistQuestion === null || typeof blacklistQuestion === 'undefined' || !Object.prototype.hasOwnProperty.call(blacklistQuestion, 'answer')) {
                return null;
            }
            return blacklistQuestion;
        },
        getHelpModeState: state => state.helpModeState,
        getWorkflowModeState (state) {
            return state.editorMode === 'singleCreationMode' ? false : state.workflowModeState
        },
        getAllChildModelItems: state => payload => {
            const { prop } = payload;
            const { childProp } = payload;
            const childParam = payload.childParam || false;
            const data = [];
            if (prop && state.model[prop]) {
                state.model[prop].forEach(item => {
                    if (childProp && item[childProp]) {
                        if (childParam) {
                            data.push(item[childProp][childParam]);
                        } else {
                            data.push(item[childProp]);
                        }
                    }
                });
            }
            return flatten(data);
        },
        getUsedPersonalDataCategoryItems (state, getters, rootState, rootGetters) {
            const arrPersonalDataCategory = [];
            const personalDataCategoyItems = rootGetters['personalDataCategories/getItems'];
            const groups = JSON.parse(
                JSON.stringify(state.model.groupsOfPersons)
            );
            groups.forEach(group => {
                group.personalDataCategory.forEach(data => {
                    const tempItem = personalDataCategoyItems.find(
                        x => x.id === data || x.clientId === data
                    );
                    let exists = false;
                    if (tempItem) {
                        exists = arrPersonalDataCategory.find(
                            x =>
                                (x.id && x.id === tempItem.id) ||
                                (x.clientId && x.clientId === tempItem.clientId)
                        );
                        if (!exists) {
                            arrPersonalDataCategory.push(tempItem);
                        }
                    }
                });
            });
            return arrPersonalDataCategory;
        }
    },
    mutations: {
        SET_EDITOR_MODE: (state, payload) => {
            state.editorMode = payload;
        },
        SET_LEA_MASTER: (state, payload) => {
            state.model.leaMaster = payload;
        },
        SET_MODEL: (state, payload) => {
            state.model = payload;
        },
        CLEAR_MODEL: state => {
            state.model = JSON.parse(JSON.stringify(modelTemplate));
        },
        SET_PROPERTY: (state, payload) => {
            Vue.set(state.model, payload.property, payload.data);
        },
        SET_PROPERTY_SILENT: (state, payload) => {
            Vue.set(state.model, payload.property, payload.data);
        },
        SET_CHILD_PROPERTY: (state, payload) => {
            Vue.set(state.model[payload.property], payload.child, payload.data);
        },
        SET_BLACKLIST_ANSWER: (state, payload) => {
            const blacklistQuestion = state.model.blacklistQuestionResult.find(x => x.blacklistQuestionId === payload.blacklistQuestionId);
            if (blacklistQuestion === null || typeof blacklistQuestion === 'undefined') {
                let blacklistQuestionResult = JSON.parse(JSON.stringify(state.model.blacklistQuestionResult));
                blacklistQuestionResult.push(payload);
                Vue.set(state.model, 'blacklistQuestionResult', blacklistQuestionResult);
            } else {
                blacklistQuestion.answer = payload.answer;
            }
        },
        SET_ROW_PROPERTY: (state, payload) => {
            Vue.set(state.model[payload.property][payload.rowIndex], payload.childProperty, payload.data);

            // remove unused PD in deletionPractise and recipients
            if (payload.property === 'groupsOfPersons' && payload.childProperty === 'personalData') {
                // get remaining PD from GOP
                let allPersonalData = [];
                state.model.groupsOfPersons.forEach(gop => {
                    allPersonalData = [...allPersonalData, ...gop.personalData];
                });

                state.model.deletionPractise.forEach((x, i) => {
                    // remove from in deletion practise
                    state.model.deletionPractise[
                        i
                    ].personalData = x.personalData.filter(y =>
                        allPersonalData.includes(y)
                    );
                });

                state.model.internalRecipients.forEach((x, i) => {
                    // remove PD from internal recipients
                    state.model.internalRecipients[i].personalData.forEach(
                        (y, j) => {
                            state.model.internalRecipients[i].personalData[
                                j
                            ].personalData = y.personalData.filter(y =>
                                allPersonalData.includes(y)
                            );
                        }
                    );
                });
                state.model.corporationRecipients.forEach((x, i) => {
                    // remove PD from corp recipients
                    state.model.corporationRecipients[i].personalData.forEach(
                        (y, j) => {
                            state.model.corporationRecipients[i].personalData[
                                j
                            ].personalData = y.personalData.filter(y =>
                                allPersonalData.includes(y)
                            );
                        }
                    );
                });
                state.model.externalRecipients.forEach((x, i) => {
                    // remove PD from external recipients
                    state.model.externalRecipients[i].personalData.forEach(
                        (y, j) => {
                            state.model.externalRecipients[i].personalData[
                                j
                            ].personalData = y.personalData.filter(y =>
                                allPersonalData.includes(y)
                            );
                        }
                    );
                });
            }

        },
        ADD_GROUP_OF_PERSONS: state => {
            const data = JSON.parse(JSON.stringify(groupOfPersonsTemplate));
            data.id = null;
            data.clientId = uuid();
            state.model.groupsOfPersons.unshift(data);
        },
        DUPLICATE_GROUP_OF_PERSONS: (state, payload) => {
            const data = JSON.parse(JSON.stringify(payload.item));
            data.id = null;
            data.clientId = uuid();
            state.model.groupsOfPersons.splice(payload.index, 0, data);
        },
        REMOVE_GROUP_OF_PERSONS: (state, payload) => {
            // get id / uuid of row which will be removed
            const gopId =
                state.model.groupsOfPersons[payload].id ||
                state.model.groupsOfPersons[payload].clientId;

            // remove GOP
            state.model.groupsOfPersons.splice(payload, 1);

            // get remaining PD from GOP
            let allPersonalData = [];
            state.model.groupsOfPersons.forEach(gop => {
                allPersonalData = [...allPersonalData, ...gop.personalData];
            });

            state.model.deletionPractise.forEach((x, i) => {
                // remove GOP in deletion practise
                state.model.deletionPractise[
                    i
                ].groupsOfPersons = x.groupsOfPersons.filter(y => y !== gopId);

                // remove PD of removed GOP from deletion practise
                // that should not be done for recipients, because the PD will be removed with the GOP, as they ar child props
                state.model.deletionPractise[
                    i
                ].personalData = x.personalData.filter(y =>
                    allPersonalData.includes(y)
                );
            });

            state.model.internalRecipients.forEach((x, i) => {
                // remove GOP from internal recipients
                state.model.internalRecipients[
                    i
                ].groupsOfPersons = x.groupsOfPersons.filter(y => y !== gopId);
                state.model.internalRecipients[
                    i
                ].personalData = x.personalData.filter(
                    y => y.groupId !== gopId
                );
            });

            state.model.corporationRecipients.forEach((x, i) => {
                // remove GOP from corp recipients
                state.model.corporationRecipients[
                    i
                ].groupsOfPersons = x.groupsOfPersons.filter(y => y !== gopId);
                state.model.corporationRecipients[
                    i
                ].personalData = x.personalData.filter(
                    y => y.groupId !== gopId
                );
            });

            state.model.externalRecipients.forEach((x, i) => {
                // remove GOP from external recipients
                state.model.externalRecipients[
                    i
                ].groupsOfPersons = x.groupsOfPersons.filter(y => y !== gopId);
                state.model.externalRecipients[
                    i
                ].personalData = x.personalData.filter(
                    y => y.groupId !== gopId
                );
            });
        },
        MOVE_GROUP_OF_PERSONS: (state, payload) => {
            Vue.move(state.model.groupsOfPersons, payload.from, payload.to);
        },
        ADD_PURPOSE_OF_PROCESSING: state => {
            const data = JSON.parse(
                JSON.stringify(purposeOfProcessingTemplate)
            );
            data.id = null;
            data.clientId = uuid();
            state.model.purposeOfProcessing.unshift(data);
        },
        DUPLICATE_PURPOSE_OF_PROCESSING: (state, payload) => {
            const data = JSON.parse(JSON.stringify(payload.item));
            data.id = null;
            data.clientId = uuid();
            state.model.purposeOfProcessing.splice(payload.index, 0, data);
        },
        REMOVE_PURPOSE_OF_PROCESSING: (state, payload) => {
            state.model.purposeOfProcessing.splice(payload, 1);
        },
        MOVE_PURPOSE_OF_PROCESSING: (state, payload) => {
            Vue.move(state.model.purposeOfProcessing, payload.from, payload.to);
        },
        ADD_PURPOSE_OF_PROCESSING_LEGITIMATE_INTEREST: (state, payload) => {
            const data = JSON.parse(
                JSON.stringify(legitimateInterestTemplate)
            );
            Vue.set(
                state.model.purposeOfProcessing[payload],
                'legitimateInterest',
                data
            );
        },
        SET_PURPOSE_OF_PROCESSING_LEGITIMATE_INTEREST: (state, payload) => {
            if (
                state.model.purposeOfProcessing[payload.index] &&
                state.model.purposeOfProcessing[payload.index]
                    .legitimateInterest
            ) {
                Vue.set(
                    state.model.purposeOfProcessing[payload.index],
                    'legitimateInterest',
                    payload.data
                );
            }
        },
        ADD_DELETION_PRACTISE: state => {
            const data = JSON.parse(JSON.stringify(deletionPractiseTemplate));
            data.id = null;
            data.clientId = uuid();
            state.model.deletionPractise.unshift(data);
        },
        DUPLICATE_DELETION_PRACTISE: (state, payload) => {
            const data = JSON.parse(JSON.stringify(payload.item));
            data.id = null;
            data.clientId = uuid();
            state.model.deletionPractise.splice(payload.index, 0, data);
        },
        REMOVE_DELETION_PRACTISE: (state, payload) => {
            state.model.deletionPractise.splice(payload, 1);
        },
        MOVE_DELETION_PRACTISE: (state, payload) => {
            Vue.move(state.model.deletionPractise, payload.from, payload.to);
        },
        ADD_INTERNAL_RECIPIENT: state => {
            const data = JSON.parse(JSON.stringify(internalRecipientTemplate));
            data.id = null;
            data.clientId = uuid();
            state.model.internalRecipients.unshift(data);
        },
        DUPLICATE_INTERNAL_RECIPIENT: (state, payload) => {
            const data = JSON.parse(JSON.stringify(payload.item));
            data.id = null;
            data.clientId = uuid();
            data.personalData.forEach(x => {
                x.id = null;
                x.clientId = uuid();
            });
            state.model.internalRecipients.splice(payload.index, 0, data);
        },
        REMOVE_INTERNAL_RECIPIENT: (state, payload) => {
            state.model.internalRecipients.splice(payload, 1);
        },
        MOVE_INTERNAL_RECIPIENT: (state, payload) => {
            Vue.move(state.model.internalRecipients, payload.from, payload.to);
        },
        ADD_INTERNAL_RECIPIENT_PERSONAL_DATA: (state, payload) => {
            Vue.set(
                state.model.internalRecipients[payload],
                'personalData',
                []
            );
        },
        ADD_CORPORATION_RECIPIENT: state => {
            const data = JSON.parse(
                JSON.stringify(corporationRecipientTemplate)
            );
            data.id = null;
            data.clientId = uuid();
            state.model.corporationRecipients.unshift(data);
        },
        DUPLICATE_CORPORATION_RECIPIENT: (state, payload) => {
            const data = JSON.parse(JSON.stringify(payload.item));
            data.id = null;
            data.clientId = uuid();
            data.personalData.forEach(x => {
                x.id = null;
                x.clientId = uuid();
            });
            state.model.corporationRecipients.splice(payload.index, 0, data);
        },
        REMOVE_CORPORATION_RECIPIENT: (state, payload) => {
            state.model.corporationRecipients.splice(payload, 1);
        },
        MOVE_CORPORATION_RECIPIENT: (state, payload) => {
            Vue.move(
                state.model.corporationRecipients,
                payload.from,
                payload.to
            );
        },
        ADD_CORPORATION_RECIPIENT_PERSONAL_DATA: (state, payload) => {
            Vue.set(
                state.model.corporationRecipients[payload],
                'personalData',
                []
            );
        },
        ADD_CORPORATION_RECIPIENT_LEGITIMATE_INTEREST: (state, payload) => {
            const data = JSON.parse(JSON.stringify(legitimateInterestTemplate));
            Vue.set(
                state.model.corporationRecipients[payload],
                'legitimateInterest',
                data
            );
        },
        SET_CORPORATION_RECIPIENT_LEGITIMATE_INTEREST: (state, payload) => {
            if (
                state.model.corporationRecipients[payload.index] &&
                state.model.corporationRecipients[payload.index]
                    .legitimateInterest
            ) {
                Vue.set(
                    state.model.corporationRecipients[payload.index],
                    'legitimateInterest',
                    payload.data
                );
            }
        },
        ADD_EXTERNAL_RECIPIENT: state => {
            const data = JSON.parse(JSON.stringify(externalRecipientTemplate));
            data.id = null;
            data.clientId = uuid();
            state.model.externalRecipients.unshift(data);
        },
        DUPLICATE_EXTERNAL_RECIPIENT: (state, payload) => {
            const data = JSON.parse(JSON.stringify(payload.item));
            data.id = null;
            data.clientId = uuid();
            data.personalData.forEach(x => {
                x.id = null;
                x.clientId = uuid();
            });
            state.model.externalRecipients.splice(payload.index, 0, data);
        },
        REMOVE_EXTERNAL_RECIPIENT: (state, payload) => {
            state.model.externalRecipients.splice(payload, 1);
        },
        MOVE_EXTERNAL_RECIPIENT: (state, payload) => {
            Vue.move(state.model.externalRecipients, payload.from, payload.to);
        },
        ADD_EXTERNAL_RECIPIENT_PERSONAL_DATA: (state, payload) => {
            Vue.set(
                state.model.externalRecipients[payload],
                'personalData',
                []
            );
        },
        ADD_EXTERNAL_RECIPIENT_LEGITIMATE_INTEREST: (state, payload) => {
            const data = JSON.parse(JSON.stringify(legitimateInterestTemplate));
            Vue.set(
                state.model.externalRecipients[payload],
                'legitimateInterest',
                data
            );
        },
        SET_EXTERNAL_RECIPIENT_LEGITIMATE_INTEREST: (state, payload) => {
            if (
                state.model.externalRecipients[payload.index] &&
                state.model.externalRecipients[payload.index].legitimateInterest
            ) {
                Vue.set(
                    state.model.externalRecipients[payload.index],
                    'legitimateInterest',
                    payload.data
                );
            }
        },
        RESET_RISK_QUESTION_MATRIX: state => {
            Vue.set(
                state.model,
                'riskQuestionMatrix',
                {...modelTemplate.riskQuestionMatrix}
            );
        },
        SET_RISK_QUESTION_MATRIX: (state, payload) => {
            Vue.set(
                state.model.riskQuestionMatrix,
                'currentQuestion',
                payload.currentQuestion
            );
            Vue.set(state.model.riskQuestionMatrix, 'result', payload.result);
            if (payload.id) {
                Vue.set(
                    state.model.riskQuestionMatrix,
                    `question${payload.id}`,
                    payload.answer
                );
            }
        },
        SET_TOM_PROPERTY: (state, payload) => {
            Vue.set(state.model.tom, payload.property, payload.data);
        },
        ADD_ENCLOSURE: (state, payload) => {
            if (!payload) {
                payload = enclosureTemplate;
            }
            payload = JSON.parse(JSON.stringify(payload));
            payload.clientId = uuid();
            state.model.enclosures.unshift(payload);
        },
        REMOVE_ENCLOSURE: (state, payload) => {
            state.model.enclosures.splice(payload, 1);
        },
        SET_ENCLOSURE_PROPERTY: (state, payload) => {
            if (state.model.enclosures[payload.index]) {
                Vue.set(
                    state.model.enclosures[payload.index],
                    payload.property,
                    payload.data
                );
            }
        },
        /**
         * Replace Model UUIDS
         * @param {*} state
         * @param {*} payload
         */
        REPLACE_UUIDS(state, payload) {
            payload.items.forEach(item => {
                if (payload.stateModelProperty === 'contactItems') {
                    const newContactItemIndex = state.model.contactItems.findIndex(x => x.clientId === item.clientId);
                    if (newContactItemIndex > -1) {
                        let newContactItem = state.model.contactItems[newContactItemIndex];
                        newContactItem.id = item.id;
                        delete newContactItem.clientId;
                        Vue.set(
                            state.model[payload.stateModelProperty],
                            newContactItemIndex,
                            newContactItem
                        );
                    }
                } else {
                    if (item.clientId) {
                        if (!payload.stateModelSubProperty) {
                            /**
                             * Replace GOP uuids with returned ids
                             */
                            if (payload.stateModelProperty === 'groupsOfPersons') {
                                const indexes = state.model[
                                    payload.stateModelProperty
                                ]
                                    .map((x, index) =>
                                        x.group &&
                                        x.group.clientId === item.clientId
                                            ? index
                                            : ''
                                    )
                                    .filter(String);
                                indexes.forEach(v => {
                                    Vue.set(
                                        state.model[payload.stateModelProperty][v]
                                            .group,
                                        'id',
                                        item.id
                                    );
                                });
                            } else  if (payload.stateModelProperty === 'blacklistQuestionResult') {
                                state.model.blacklistQuestionResult.forEach((answer, answerIndex) => {
                                    const index = payload.items.findIndex(x => x.clientId === answer.clientId);
                                    if (index > -1) {
                                        Vue.set(
                                            state.model.blacklistQuestionResult[answerIndex],
                                            'id',
                                            payload.items[index].id
                                        );
                                        Vue.set(
                                            state.model.blacklistQuestionResult[answerIndex],
                                            'clientId',
                                            null
                                        );
                                    }
                                });
                            } else {
                                /**
                                 * Replace other model uuids
                                 */
                                const index = state.model[
                                    payload.stateModelProperty
                                ].findIndex(x => x === item.clientId);
                                Vue.set(
                                    state.model[payload.stateModelProperty],
                                    index,
                                    item.id
                                );
                            }
                        } else {
                            /**
                             * Replace child uuids with ids (e.g. personalData uuids inside GOP field ['1','1ab...','3'], or recipients personal data [{id: null, clientId: 1ab}, {id: null, clientId: 2ab}]) 
                             */
                            state.model[payload.stateModelProperty].forEach((group, groupIndex) => {
                                let stateModelSubProperty = state.model[payload.stateModelProperty][groupIndex][payload.stateModelSubProperty];
                                if ( Array.isArray(stateModelSubProperty) && stateModelSubProperty.length )
                                {
                                    stateModelSubProperty.forEach((x, i) => {
                                        if(Object.prototype.hasOwnProperty.call(payload, 'stateModelSubChildProperty')) {
                                            let stateModelSubChildProperty = state.model[payload.stateModelProperty][groupIndex][payload.stateModelSubProperty][i][payload.stateModelSubChildProperty];
                                            stateModelSubChildProperty.forEach((xx, ii) => {
                                                if(isUUID(xx) && xx === item.clientId) {
                                                    Vue.set(
                                                        state.model[payload.stateModelProperty][groupIndex][payload.stateModelSubProperty][i][payload.stateModelSubChildProperty],
                                                        ii,
                                                        item.id
                                                    );
                                                }
                                            });
                                        } else if (
                                            typeof x === 'object' 
                                            && x !== null 
                                            && Object.prototype.hasOwnProperty.call(x, 'clientId')
                                            && x.clientId === item.clientId
                                        )
                                        {
                                            Vue.set(
                                                state.model[payload.stateModelProperty][groupIndex][payload.stateModelSubProperty][i],
                                                'id',
                                                item.id
                                            );
                                            Vue.set(
                                                state.model[payload.stateModelProperty][groupIndex][payload.stateModelSubProperty][i],
                                                'clientId',
                                                null
                                            );

                                        } else if(isUUID(x) && x === item.clientId) {
                                            Vue.set(
                                                state.model[payload.stateModelProperty][groupIndex][payload.stateModelSubProperty],
                                                i,
                                                item.id
                                            );
                                        }
                                    });
                                }
                            });
                        }
                    }
                }
            });
        },
        /**
         * Replace Model Row UUIDS
         * @param {*} state
         * @param {*} payload
         */
        REPLACE_ROW_UUIDS(state, payload) {
            const { property } = payload;
            payload.items.forEach(item => {
                if (item.clientId) {
                    const groupIndex = state.model[property].findIndex(
                        x => x.clientId === item.clientId
                    );
                    Vue.set(state.model[property][groupIndex], 'id', item.id);
                    Vue.set(
                        state.model[property][groupIndex],
                        'clientId',
                        null
                    );

                    /**
                     * Replace GOP row uuids in other dependent model properties
                     */
                    [
                        'deletionPractise',
                        'internalRecipients',
                        'externalRecipients',
                        'corporationRecipients',
                    ].forEach(modelChild => {
                        state.model[modelChild].forEach(
                            (modelChildItem, modelChildItemIndex) => {
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        modelChildItem,
                                        'groupsOfPersons'
                                    )
                                ) {
                                    const indexG = modelChildItem.groupsOfPersons.findIndex(
                                        x => x === item.clientId
                                    );
                                    Vue.set(
                                        state.model[modelChild][
                                            modelChildItemIndex
                                        ].groupsOfPersons,
                                        indexG,
                                        item.id
                                    );
                                }
                                /**
                                 * Replace GOP row uuids in recipients personalData field
                                 * e.g. @personalData: [{"groupId": REPLACEMENT,"personalData": []}]
                                 */
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        modelChildItem,
                                        'personalData'
                                    ) &&
                                    modelChildItem !== 'deletionPractise'
                                ) {
                                    const indexP = modelChildItem.personalData.findIndex(
                                        x => x && x.groupId === item.clientId
                                    );
                                    if (indexP > -1) {
                                        Vue.set(
                                            state.model[modelChild][
                                                modelChildItemIndex
                                            ].personalData,
                                            indexP,
                                            {
                                                ...state.model[modelChild][
                                                    modelChildItemIndex
                                                ].personalData[indexP],
                                                ...{ groupId: item.id },
                                            }
                                        );
                                    }
                                }
                            }
                        );
                    });
                }
            });
        },
        SET_HELP_MODE_STATE: (state, payload) => {
            state.helpModeState = payload;
        },
        SET_WORKFLOW_MODE_STATE: (state, payload) => {
            state.workflowModeState = payload;
        },
    },
    actions: {
        clearModel: context =>
            new Promise(resolve => {
                context.commit('CLEAR_MODEL');
                resolve();
        }),
        mergeModel: (context, model) =>
            new Promise(resolve => {
                const mergedProcess = { ...context.state.model, ...model };
                if (mergedProcess.riskAssessment === null) {
                    mergedProcess.riskAssessment = {...modelTemplate.riskAssessment};
                }
                if (mergedProcess.similarProcessingActivity === null) {
                    mergedProcess.similarProcessingActivity = {...modelTemplate.similarProcessingActivity};
                }
                if (mergedProcess.riskQuestionMatrix === null) {
                    context.commit('RESET_RISK_QUESTION_MATRIX');
                }
                context.commit('SET_WORKFLOW_MODE_STATE', false);
                setTimeout(() => {
                    context.commit('SET_MODEL', mergedProcess);
                    resolve();
                }, 1000);
            }),
        setEditorMode: (context, payload) => {
            const { create } = payload;
            const { template } = payload;
            const { numOfCompanies } = payload;
            let editorMode = null;

            if (!create && !template) {
                editorMode = 'singleEditMode';
            } else if (!create && template) {
                editorMode = 'templateEditMode';
            } else if (create && template) {
                editorMode = 'templateCreationMode';
            } else if (create && !template) {
                if (numOfCompanies > 1) {
                    editorMode = 'multipleCreationMode';
                } else {
                    editorMode = 'singleCreationMode';
                }
            }

            return new Promise(resolve => {
                context.commit('SET_EDITOR_MODE', editorMode);
                resolve();
            });
        },
        setLeaMaster: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_LEA_MASTER', payload);
                resolve();
            }),
        setModel: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_MODEL', payload);
                resolve();
            }),
        setProperty: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_PROPERTY', payload);
                resolve();
            }),
        setPropertySilent: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_PROPERTY_SILENT', payload);
                resolve();
            }),
        setChildProperty: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_CHILD_PROPERTY', payload);
                resolve();
            }),
        setBlacklistAnswer: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_BLACKLIST_ANSWER', payload);
                resolve();
            }),
        setRowProperty: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_ROW_PROPERTY', payload);
                resolve();
            }),
        addGroupOfPersons: (context, payload) =>
            new Promise(resolve => {
                if (!payload) {
                    context.commit('ADD_GROUP_OF_PERSONS');
                } else {
                    context.commit('DUPLICATE_GROUP_OF_PERSONS', payload);
                }
                resolve();
            }),
        removeGroupOfPersons: (context, payload) =>
            new Promise(resolve => {
                context.commit('REMOVE_GROUP_OF_PERSONS', payload);
                resolve();
            }),
        moveGroupOfPersons: (context, payload) =>
            new Promise(resolve => {
                context.commit('MOVE_GROUP_OF_PERSONS', payload);
                resolve();
            }),
        addPurposeOfProcessing: (context, payload) =>
            new Promise(resolve => {
                if (!payload) {
                    context.commit('ADD_PURPOSE_OF_PROCESSING');
                } else {
                    context.commit('DUPLICATE_PURPOSE_OF_PROCESSING', payload);
                }
                resolve();
            }),
        removePurposeOfProcessing: (context, payload) =>
            new Promise(resolve => {
                context.commit('REMOVE_PURPOSE_OF_PROCESSING', payload);
                resolve();
            }),
        movePurposeOfProcessing: (context, payload) =>
            new Promise(resolve => {
                context.commit('MOVE_PURPOSE_OF_PROCESSING', payload);
                resolve();
            }),
        addPurposeOfProcessingLegitimateInterest: (context, payload) =>
            new Promise(resolve => {
                context.commit(
                    'ADD_PURPOSE_OF_PROCESSING_LEGITIMATE_INTEREST',
                    payload
                );
                resolve();
            }),
        setPurposeOfProcessingLegitimateInterest: (context, payload) =>
            new Promise(resolve => {
                context.commit(
                    'SET_PURPOSE_OF_PROCESSING_LEGITIMATE_INTEREST',
                    payload
                );
                resolve();
            }),
        addDeletionPractise: (context, payload) =>
            new Promise(resolve => {
                if (!payload) {
                    context.commit('ADD_DELETION_PRACTISE');
                } else {
                    context.commit('DUPLICATE_DELETION_PRACTISE', payload);
                }
                resolve();
            }),
        removeDeletionPractise: (context, payload) =>
            new Promise(resolve => {
                context.commit('REMOVE_DELETION_PRACTISE', payload);
                resolve();
            }),
        moveDeletionPractise: (context, payload) =>
            new Promise(resolve => {
                context.commit('MOVE_DELETION_PRACTISE', payload);
                resolve();
            }),
        addInternalRecipient: (context, payload) =>
            new Promise(resolve => {
                if (!payload) {
                    context.commit('ADD_INTERNAL_RECIPIENT');
                } else {
                    context.commit('DUPLICATE_INTERNAL_RECIPIENT', payload);
                }
                resolve();
            }),
        removeInternalRecipient: (context, payload) =>
            new Promise(resolve => {
                context.commit('REMOVE_INTERNAL_RECIPIENT', payload);
                resolve();
            }),
        moveInternalRecipient: (context, payload) =>
            new Promise(resolve => {
                context.commit('MOVE_INTERNAL_RECIPIENT', payload);
                resolve();
            }),
        addInternalRecipientPersonalData: (context, payload) =>
            new Promise(resolve => {
                context.commit('ADD_INTERNAL_RECIPIENT_PERSONAL_DATA', payload);
                resolve();
            }),
        addCorporationRecipient: (context, payload) =>
            new Promise(resolve => {
                if (!payload) {
                    context.commit('ADD_CORPORATION_RECIPIENT');
                } else {
                    context.commit('DUPLICATE_CORPORATION_RECIPIENT', payload);
                }
                resolve();
            }),
        removeCorporationRecipient: (context, payload) =>
            new Promise(resolve => {
                context.commit('REMOVE_CORPORATION_RECIPIENT', payload);
                resolve();
            }),
        moveCorporationRecipient: (context, payload) =>
            new Promise(resolve => {
                context.commit('MOVE_CORPORATION_RECIPIENT', payload);
                resolve();
            }),
        addCorporationRecipientPersonalData: (context, payload) =>
            new Promise(resolve => {
                context.commit(
                    'ADD_CORPORATION_RECIPIENT_PERSONAL_DATA',
                    payload
                );
                resolve();
            }),
        addCorporationRecipientLegitimateInterest: (context, payload) =>
            new Promise(resolve => {
                context.commit(
                    'ADD_CORPORATION_RECIPIENT_LEGITIMATE_INTEREST',
                    payload
                );
                resolve();
            }),
        setCorporationRecipientLegitimateInterest: (context, payload) =>
            new Promise(resolve => {
                context.commit(
                    'SET_CORPORATION_RECIPIENT_LEGITIMATE_INTEREST',
                    payload
                );
                resolve();
            }),
        addExternalRecipient: (context, payload) =>
            new Promise(resolve => {
                if (!payload) {
                    context.commit('ADD_EXTERNAL_RECIPIENT');
                } else {
                    context.commit('DUPLICATE_EXTERNAL_RECIPIENT', payload);
                }
                resolve();
            }),
        removeExternalRecipient: (context, payload) =>
            new Promise(resolve => {
                context.commit('REMOVE_EXTERNAL_RECIPIENT', payload);
                resolve();
            }),
        moveExternalRecipient: (context, payload) =>
            new Promise(resolve => {
                context.commit('MOVE_EXTERNAL_RECIPIENT', payload);
                resolve();
            }),
        addExternalRecipientPersonalData: (context, payload) =>
            new Promise(resolve => {
                context.commit('ADD_EXTERNAL_RECIPIENT_PERSONAL_DATA', payload);
                resolve();
            }),
        addExternalRecipientLegitimateInterest: (context, payload) =>
            new Promise(resolve => {
                context.commit(
                    'ADD_EXTERNAL_RECIPIENT_LEGITIMATE_INTEREST',
                    payload
                );
                resolve();
            }),
        setExternalRecipientLegitimateInterest: (context, payload) =>
            new Promise(resolve => {
                context.commit(
                    'SET_EXTERNAL_RECIPIENT_LEGITIMATE_INTEREST',
                    payload
                );
                resolve();
            }),
        setRiskQuestionMatrix: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_RISK_QUESTION_MATRIX', payload);
                resolve();
            }),
        resetQuestionMatrix: context =>
            new Promise(resolve => {
                context.commit('RESET_RISK_QUESTION_MATRIX');
                resolve();
            }),
        setTomProperty: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_TOM_PROPERTY', payload);
                resolve();
            }),
        addEnclosure: (context, payload) =>
            new Promise(resolve => {
                context.commit('ADD_ENCLOSURE', payload);
                resolve();
            }),
        removeEnclosure: (context, payload) =>
            new Promise(resolve => {
                context.commit('REMOVE_ENCLOSURE', payload);
                resolve();
            }),
        setEnclosureProperty: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_ENCLOSURE_PROPERTY', payload);
                resolve();
            }),
        replaceModelUuids: (context, payload) =>
            new Promise(resolve => {
                context.commit('REPLACE_UUIDS', payload);
                resolve();
            }),
        replaceModelRowUuids: (context, payload) =>
            new Promise(resolve => {
                context.commit('REPLACE_ROW_UUIDS', payload);
                resolve();
            }),
        setHelpModeState: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_HELP_MODE_STATE', payload);
                context.commit('SET_WORKFLOW_MODE_STATE', !payload);
                eventBus.$emit('scrollHeader_update');
                resolve();
            }),
        setWorkflowModeState: (context, payload) =>
            new Promise(resolve => {
                context.commit('SET_WORKFLOW_MODE_STATE', payload);
                context.commit('SET_HELP_MODE_STATE', !payload);
                eventBus.$emit('scrollHeader_update');
                resolve();
            }),
        toggleHelpModeState: (context) =>
            new Promise(resolve => {
                if (context.state.helpModeState) {
                    context.commit('SET_HELP_MODE_STATE', false);
                    context.commit('SET_WORKFLOW_MODE_STATE', false);
                } else {
                    context.commit('SET_HELP_MODE_STATE', true);
                    context.commit('SET_WORKFLOW_MODE_STATE', false);
                }
                eventBus.$emit('scrollHeader_update');
                resolve();
            }),
            
        toggleWorkflowModeState: (context) =>
            new Promise(resolve => {
                if (context.state.workflowModeState) {
                    context.commit('SET_WORKFLOW_MODE_STATE', false);
                    context.commit('SET_HELP_MODE_STATE', false);
                } else {
                    context.commit('SET_WORKFLOW_MODE_STATE', true);
                    context.commit('SET_HELP_MODE_STATE', false);
                }
                eventBus.$emit('scrollHeader_update');
                resolve();
            }),
    },
};
