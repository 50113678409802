import http from '@/utils/axios';
import { deleteToken, storeToken, getToken, getDetails } from '../utils/auth';

/**
 * This store handles the login process
 */
export default {
    namespaced: true,
    state: {
        status: '',
        token: getToken || null,
        user: '',
    },
    mutations: {
        AUTH_REQUEST(state) {
            state.status = 'loading';
        },
        AUTH_SUCCESS(state, userData) {
            if (process.env.VUE_APP_SSO !== 'true' && process.env.VUE_APP_DISABLE_INTERCEPTORS !== 'true') {
                http.defaults.headers.common.Authorization = `Bearer ${userData.token}`;
            }
            storeToken(
                userData.token,
                userData.stayLoggedIn,
                userData.refresh_token
            );
            state.status = 'success';
            if (getDetails() && getDetails().preferredLanguage) {
                http.defaults.headers.common[
                    'Accept-Language'
                ] = `${getDetails().preferredLanguage.toLowerCase()}`;
            }
        },
        REMOVE_TOKEN() {
            deleteToken();
        },
        AUTH_ERROR(state) {
            deleteToken();
            state.status = 'error';
        },
    },
    actions: {
        recreateToken({ commit }, token) {
            commit('AUTH_SUCCESS', { token });
        },
        login({ commit }, authData) {
            commit('AUTH_REQUEST');
            return new Promise((resolve, reject) => {
                http.post('/api/login_check', {
                    username: authData.username,
                    password: authData.password,
                })
                    .then(response => {
                        commit('AUTH_SUCCESS', {
                            token: response.data.token,
                            stayLoggedIn: authData.stayLoggedIn,
                            refresh_token: response.data.refresh_token,
                        });
                        resolve(getDetails());
                    })
                    .catch(err => {
                        commit('AUTH_ERROR');
                        reject(err);
                    });
            });
        },
        logoutUser({ commit, dispatch }) {
            dispatch('eventStore/clearAll', null, { root: true }).then(() => {
                commit('REMOVE_TOKEN');
            });
        },
    },
    getters: {
        getJwtToken: () => getToken(),
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    },
};
